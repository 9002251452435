import decode from "jwt-decode";
import { History } from "history";
import { withRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";

import "./Settings.scss";
import { OTPSchema } from "./schema";
import Spacer from "../../components/Spacer";
import authToken from "../../services/authToken";
import SmallTextField from "../../components/Custom/SmallTextField";
import {
  requestVerification,
  verifyAccount,
} from "../../services/graphql/accountVerification";

export interface AccountVerificationProps {
  history: History;
}

const AccountVerification: React.SFC<AccountVerificationProps> = ({
  history,
}) => {
  const [dialog, setDialog] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [reqVerfication, setReqVerification] = useState(false);

  useEffect(() => {
    const token = authToken.getToken();
    try {
      const { verified } = decode(token);
      if (verified) {
        setIsVerified(true);
      } else {
        setIsVerified(false);
      }
    } catch {
      setIsVerified(false);
    }
  }, []);

  const handleClose = () => {
    setDialog(false);
  };

  async function requestAccountVerification(): Promise<boolean> {
    setReqVerification(true);
    const requested = await requestVerification();
    if (requested) setDialog(true);
    setReqVerification(false);
    return requested;
  }

  async function verifiyUserAccount(
    { otp }: any,
    helper: FormikHelpers<{ otp: string }>
  ) {
    helper.setSubmitting(true);
    await verifyAccount(otp, history);
    helper.setSubmitting(false);
    setDialog(false);
  }

  return (
    <div>
      <div className="account-verification-main-container">
        <Typography variant="subtitle1" component="p">
          Status
        </Typography>
      </div>
      <Spacer height={10} />
      <div
        className="account-verification-status-wrapper"
      >
        <div>
          <Typography variant="subtitle1" component="p">
            {isVerified ? "Account is verified 😃" : "Not verified"}
          </Typography>
        </div>
        <div>
          <Button
            color="primary"
            variant="contained"
            style={{ display: isVerified ? "none" : "block" }}
            disabled={reqVerfication}
            onClick={async () => await requestAccountVerification()}
          >
            {reqVerfication ? (
              <CircularProgress size={15} style={{ marginRight: "10px" }} />
            ) : null}
            {reqVerfication ? "Sending Otp": "Verify Now"}
          </Button>
        </div>
      </div>
      <Spacer height={10} />

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialog}
      >
        <Formik
          validateOnMount
          initialValues={{ otp: "" }}
          validationSchema={OTPSchema}
          onSubmit={verifiyUserAccount}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <DialogTitle>Verify your account</DialogTitle>
              <DialogContent>
                <Typography>Enter your OTP</Typography>
                <Spacer height={10} />
                <Field
                  name="otp"
                  as={SmallTextField}
                  label="Verification Code"
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                  Close
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      size={15}
                      style={{ marginRight: "10px" }}
                    />
                  ) : null}
                  {isSubmitting ? "Veryfying..." : "Verify"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default withRouter(AccountVerification);
