import { Feeds, FeedPayload, FeedsAction } from "../interfaces/global-interfaces";

export default function feedsReducer(state: FeedPayload[] = [], action: Feeds) {
    switch (action.type) {
        case FeedsAction.Update:
            state = [...action.payload];
            return state;
        default:
            state = [...state];
            return state;
    }
}