import * as yup from "yup";

export default yup.object({
  dob: yup.string().notRequired(),
  state: yup.string().notRequired(),
  country: yup.string().notRequired(),
  email: yup
    .string()
    .email("Is not a valid email")
    .required("Email is required"),
  phone: yup
    .string()
    .notRequired()
    .min(8, "Invalid phone number")
    .max(15, "Invalid phone number"),
  lastName: yup
    .string()
    .notRequired()
    .min(1, "Min 1 character")
    .max(20, "Max 1 character"),
  username: yup
    .string()
    .required("Username is required")
    .min(10, "Min 10 character")
    .max(50, "Min 50 character"),
  firstName: yup
    .string()
    .notRequired()
    .min(1, "Min 1 character")
    .max(20, "Max 20 character"),
  password: yup
    .string()
    .required("Password is required")
    .min(10, "Min 10 character")
    .max(100, "Max 100 character"),
  repassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password")], 'Password not match'),
});
