import React from "react";
import { History } from "history";
import { withRouter } from "react-router-dom";
import { AppBar, Button, Toolbar } from "@material-ui/core";

import "./Component.scss";
import Spacer from "./Spacer";
import AppLogo from "../../assets/logo.png";
import authToken from "../services/authToken";
import gqlClient from "../services/graphql/gqlClient";
import { TokenType } from "../interfaces/global-interfaces";

export interface TopAppBarProps {
  history: History;
}

const TopAppBar: React.SFC<TopAppBarProps> = ({ history }) => {
  function logOut() {
    gqlClient.resetClient();
    authToken.setToken("");
    localStorage.removeItem(TokenType.RefreshToken);
    history.push("/login");
  }

  return (
    <AppBar position="static" className="app-bar-main">
      <Toolbar>
        <img src={AppLogo} alt="Logo" className="app-bar-logo-image" />
        <Spacer width={10} height={1} />

        <div className="appbar-filler"></div>
        <Button color="primary" onClick={logOut} variant="outlined">
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(TopAppBar);
