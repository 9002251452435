import decode from "jwt-decode";
import { History } from "history";
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Paper, Button, Divider } from "@material-ui/core";
import {
  RecentActors,
  SettingsRounded,
  DashboardRounded,
  LocalMallRounded,
  ContactSupportRounded,
  SupervisorAccountRounded,
} from "@material-ui/icons";

import "./Component.scss";
import authToken from "../services/authToken";

export interface NavMenuProps {
  history: History;
}

const NavMenu: React.SFC<NavMenuProps> = ({ history }) => {
  const [admin, setAdmin] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  useEffect(() => {
    checkAdmin();
    setCurrentPath(history.location.pathname);
  }, [history.location.pathname]);

  function checkAdmin() {
    const access_token = authToken.getToken();

    try {
      const { role } = decode(access_token);
      if (role === "admin") setAdmin(true);
    } catch {
      setAdmin(false);
    }
  }

  return (
    <div className="main-navigation-wrapper">
      <Paper elevation={3} className="nav-paper">
        <div>
          <Button
            fullWidth
            size="small"
            variant="text"
            onClick={() => {
              history.push("/");
            }}
            startIcon={<DashboardRounded />}
            color={currentPath === "/" ? "primary" : "default"}
          >
            Dashboard
          </Button>
        </div>
        <div>
          <Divider
            flexItem
            component="div"
            variant="middle"
            orientation="vertical"
          />
        </div>
        <div>
          <Button
            fullWidth
            size="small"
            variant="text"
            onClick={() => {
              history.push("/products");
            }}
            startIcon={<LocalMallRounded />}
            color={currentPath.startsWith("/products") ? "primary" : "default"}
          >
            Products
          </Button>
        </div>
        <div>
          <Divider
            flexItem
            component="div"
            variant="middle"
            orientation="vertical"
          />
        </div>
        <div>
          <Button
            fullWidth
            size="small"
            variant="text"
            onClick={() => {
              history.push("/user-activity");
            }}
            startIcon={<RecentActors />}
            color={currentPath === "/user-activity" ? "primary" : "default"}
          >
            User Activity
          </Button>
        </div>

        <div>
          <Divider
            flexItem
            component="div"
            variant="middle"
            orientation="vertical"
          />
        </div>
        <div>
          <Button
            fullWidth
            size="small"
            variant="text"
            onClick={() => {
              history.push("/settings");
            }}
            startIcon={<SettingsRounded />}
            color={currentPath === "/settings" ? "primary" : "default"}
          >
            Settings
          </Button>
        </div>
        <div>
          <Divider
            flexItem
            component="div"
            variant="middle"
            orientation="vertical"
          />
        </div>
        <div>
          <Button
            fullWidth
            size="small"
            variant="text"
            onClick={() => {
              history.push("/get-licenses");
            }}
            startIcon={<ContactSupportRounded />}
            color={currentPath === "/get-licenses" ? "primary" : "default"}
          >
            Get Licenses
          </Button>
        </div>
        {admin ? (
          <>
            <div>
              <Divider
                flexItem
                component="div"
                variant="middle"
                orientation="vertical"
              />
            </div>
            <div>
              <Button
                fullWidth
                size="small"
                variant="text"
                onClick={() => {
                  history.push("/admin");
                }}
                startIcon={<SupervisorAccountRounded />}
                color={currentPath === "/admin" ? "primary" : "default"}
              >
                Admin
              </Button>
            </div>
          </>
        ) : null}
      </Paper>
    </div>
  );
};

export default withRouter(NavMenu);
