import gql from 'graphql-tag';
import { OperationContext } from 'urql/core';


import gqlClient from './gqlClient';
import history from '../../history';
import authToken from '../authToken';
import alertService from '../alertService';
import getFingerPrint from '../fingerPrint';
import { TokenType } from '../../interfaces/global-interfaces';



export default async function refreshToken(): Promise<void> {
    const client = gqlClient.getClient();
    const machineId = await getFingerPrint();
    const refreshToken = localStorage.getItem(TokenType.RefreshToken) || ''
    
    const options: Partial<OperationContext> = {
        requestPolicy: "network-only"
    }

    const query = gql`
        mutation($refreshToken: String!, $machineId: String!){
            refreshToken(refreshToken:$refreshToken, machineId:$machineId){
                access_token
            }
        }
    `

    const response = await client.mutation(query, { refreshToken, machineId }, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure', 'error');
            return;
        }
        alertService(response.error.graphQLErrors[0].message, 'warning');
        history.push('/login');
        return;
    }

    authToken.setToken(response.data.refreshToken.access_token);

}