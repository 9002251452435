import gql from "graphql-tag";

import gqlClient from "./gqlClient";
import alertService from "../alertService";

export async function reqResetPassword(username: string): Promise<boolean> {
  const client = gqlClient.getClient();

  const query = gql`
    mutation($username: String!) {
      reqResetPassword(username: $username)
    }
  `;

  const response = await client.query(query, { username }).toPromise();

  if (response.error) {
    if (response.error.networkError) {
      alertService("Network failure", "error");
      return false;
    }
    alertService(response.error.graphQLErrors[0].message, "error");
    return false;
  }

  const reqResetPassword = response.data.reqResetPassword;
  return reqResetPassword;
}

export async function resetPassword(
  otp: string,
  password: string
): Promise<boolean> {
  const client = gqlClient.getClient();

  const query = gql`
    mutation($otp: String!, $password: String!) {
      resetPassword(otp: $otp, newPassword: $password)
    }
  `;

  const response = await client.query(query, { otp, password }).toPromise();

  if (response.error) {
    if (response.error.networkError) {
      alertService("Network failure", "error");
      return false;
    }
    alertService(response.error.graphQLErrors[0].message, "error");
    return false;
  }

  const resetPassword = response.data.resetPassword;
  return resetPassword;
}
