import React from "react";
import { InfoRounded, ErrorRounded, WarningRounded } from "@material-ui/icons";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";

interface ActivityLogListItemProps {
  level: any;
  action: any;
  timestamp: any;
  description: any;
}

const ActivityLogListItem: React.SFC<ActivityLogListItemProps> = ({
  level,
  action,
  timestamp,
  description,
}) => {
  function levelIcon() {
    switch (level) {
      case "INFO":
        return (
          <Avatar style={{ backgroundColor: "#3f7cff" }}>
            <InfoRounded style={{ color: "white" }} />
          </Avatar>
        );
      case "WARNNING":
        return (
          <Avatar style={{ backgroundColor: "#ff7c2b" }}>
            <WarningRounded style={{ color: "white" }} />
          </Avatar>
        );
      case "ERROR":
        return (
          <Avatar style={{ backgroundColor: "#fc4141" }}>
            <ErrorRounded style={{ color: "white" }} />
          </Avatar>
        );
      default:
        return (
          <Avatar style={{ backgroundColor: "#3f7cff" }}>
            <InfoRounded style={{ color: "white" }} />
          </Avatar>
        );
    }
  }

  return (
    <ListItem>
      <ListItemAvatar>{levelIcon()}</ListItemAvatar>
      <ListItemText
        primary={`${action} -- ${new Date(
          parseInt(timestamp)
        ).toLocaleDateString()} -- ${new Date(
          parseInt(timestamp)
        ).toLocaleTimeString()}`}
        secondary={description}
      />
    </ListItem>
  );
};

export default ActivityLogListItem;
