import gql from 'graphql-tag';
import { OperationContext } from 'urql/core';

import gqlClient from './gqlClient';
import authToken from '../authToken';
import alertService from '../alertService';


export default async function assignLicenes(
    amount: number,
    duration: number,
    productId: string,
    moderatorUsername: string,
): Promise<boolean> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: "network-only"
    }

    const query = gql`
        mutation($moderatorUsername: String!, $productId: String!, $amount: Int!, $duration: Int!) {
            assignLicenses(
                amount: $amount,
                duration: $duration,
                productId: $productId,
                moderatorUsername: $moderatorUsername
            )
        }
    `

    const response = await client.query(query, { amount, duration, productId, moderatorUsername }, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure', 'error');
            return false;
        }
        alertService(response.error.graphQLErrors[0].message, 'error');
        return false;
    }

    const licensesAmount = response.data.assignLicenses as number;
    alertService(`Total ${licensesAmount} assign to user ${moderatorUsername}`, 'success')
    return true;
}