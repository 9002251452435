import gql from "graphql-tag";
import { OperationContext } from "urql/core";

import gqlClient from './gqlClient';
import authToken from '../authToken';
import store from '../../store/store';
import alertService from '../alertService';
import activityLogsAction from '../../actions/activityLogsAction';
import { ActivityLogsPayload } from "../../interfaces/global-interfaces";

export default async function getUserActivity(ignoreCache: boolean): Promise<void> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token } 
            }
        },
        requestPolicy: "network-only"
    }

    const query = gql`
        query{
            getLogs{
                id
                level
                action
                timestamp
                description
            }

        }
    `

    const response = await client.query(query, {}, options).toPromise();
    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure.', 'error');
            return;
        }
        alertService(response.error.graphQLErrors[0].message, 'error');
        return;
    }


    const activityLogs: ActivityLogsPayload[] = response.data.getLogs.map((eachLog: any) => {
        delete eachLog.__typename;
        return eachLog;
    })
    store.dispatch(activityLogsAction(activityLogs))

}