import React from "react";
import { History } from "history";
import { Slide } from "@material-ui/core";

import "./Dashboard.scss";
import QuickStatus from "./QuickStatus";
import Spacer from "../../components/Spacer";
import FeedAndActivity from "./FeedAndActivity";
import authToken from "../../services/authToken";
import CopyRight from "../../components/Copyright";
import updateDashboard from "../../services/graphql/dashboard";
import { ActivityLogsPayload } from "../../interfaces/global-interfaces";

export interface DashboardProps {
  history: History;
  logs: ActivityLogsPayload[];
}

export interface DashboardState {
  fetching: boolean;
}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
  state = {
    fetching: true,
  };

  async componentDidMount() {
    
    if (!authToken.hasToken) {
      this.props.history.push("/login");
    } else {
      await updateDashboard();
      this.setState({ fetching: false });
    }
  }

  render() {
    const { fetching } = this.state;
    return (
      <>
        <div className="dashboard-wrapper">
          <div className="dashboard-inner-wrapper">
            <Slide in={true} direction="right" mountOnEnter unmountOnExit>
              <div>
                <QuickStatus fetching={fetching} />

                <Spacer height={20} />

                <FeedAndActivity fetching={fetching} />
              </div>
            </Slide>
          </div>
        </div>
        <CopyRight />
      </>
    );
  }
}

export default Dashboard;
