import React from "react";
import { connect } from "react-redux";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { Typography, Button, CircularProgress } from "@material-ui/core";

import "./Admin.scss";
import Feeds from "../Feeds";
import { addFeedSchema } from "./schema";
import Spacer from "../../components/Spacer";
import { addFeed } from "../../services/graphql/feeds";
import SmallTextField from "../../components/Custom/SmallTextField";

export interface AssignLicensesProps {}

interface SubmitValues {
  title: string;
  description: string;
}

const AddOrRemoveFeeds: React.SFC<AssignLicensesProps> = () => {
  const initVaues = { title: "", description: "" };

  async function handleSubmit(
    values: SubmitValues,
    helper: FormikHelpers<SubmitValues>
  ) {
    const { title, description } = values;
    helper.setSubmitting(true);
    const done = await addFeed(title, description);
    helper.setSubmitting(false);
    if (done) helper.resetForm();
  }

  return (
    <div>
      <div className="add-or-remove-title-container">
        <Typography variant="subtitle1" component="p">
          Add / Remove Feeds
        </Typography>
      </div>
      <Spacer height={10} />
      <div className="add-remove-container">
        <div className="add-remove-inner-wrapper">
          <Formik
            validateOnMount
            onSubmit={handleSubmit}
            initialValues={initVaues}
            validationSchema={addFeedSchema}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <div className="add-remove-field-wrapper">
                  <Field name="title" label="Title" as={SmallTextField} />
                </div>
                <Spacer height={10} />
                <div className="add-remove-field-wrapper">
                  <Field
                    multiline
                    rows={5}
                    rowsMax={5}
                    name="description"
                    label="Description"
                    as={SmallTextField}
                  />
                </div>
                <Spacer height={10} />
                <div className="add-remove-field-wrapper">
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        size={15}
                        color="primary"
                        className="add-remove-progress"
                      />
                    ) : null}
                    {isSubmitting ? "Adding..." : "Add Feed"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="add-remove-feeds-wrapper">
          <Feeds admin heightOffset={500} light />
        </div>
      </div>
      <Spacer height={10} />
    </div>
  );
};

function mapStateToProps(state: any) {
  const products = state.products;
  return {
    products,
  };
}

export default connect(mapStateToProps, null)(AddOrRemoveFeeds);
