import * as yup from "yup";

export default yup.object({
  password: yup.string().required().min(10).max(100),
  retypePassword: yup
    .string()
    .required("Retype password is required")
    .oneOf([yup.ref("password")], "Password not matched"),
});

export const OTPSchema = yup.object({
  otp: yup.string().required().min(6).max(6),
});

export const updateInfoSchema = yup.object({
  phone: yup.string().notRequired(),
  country: yup.string().notRequired(),
  lastName: yup.string().notRequired(),
  firstName: yup.string().notRequired(),
  countryState: yup.string().notRequired(),
  dob: yup.string().notRequired().nullable(),
});
