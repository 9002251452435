class TempAuthToken {
    private token: string;
    constructor() {
        this.token = '';
    }

    setToken(newToken: string): void {
        this.token = newToken;
    }

    getToken(): string{
        return this.token;
    }

    get hasToken(): boolean{
        return !!this.token;
    }
}


export default new TempAuthToken()