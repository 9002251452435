import {
  Paper,
  Slide,
  Button,
  MenuItem,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { History } from "history";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Formik, FastField, Form } from "formik";
import React, { useState, useEffect } from "react";

import "./Signup.scss";
import schema from "./imutable/schema";
import { signUpHandler } from "./signupHandler";
import Spacer from "../../../components/Spacer";
import AppLogo from "../../../../assets/logo.png";
import initialValues from "./imutable/initialValues";
import CopyRight from "../../../components/Copyright";
import DatePicker from "../../../components/Custom/DatePicker";
import PasswordField from "../../../components/Custom/PasswordField";
import SmallTextField from "../../../components/Custom/SmallTextField";

export interface SignupProps {
  history: History;
}

const Signup: React.SFC<SignupProps> = ({ history }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetch(
      "https://restcountries.eu/rest/v2/all?fields=alpha3Code;alpha2Code;callingCodes;name"
    )
      .then((res) => res.json())
      .then((data) => {
        let countriesFilterd = data.map((each: any) => ({
          flag: `https://www.countryflags.io/${each.alpha2Code.toLowerCase()}/flat/16.png`,
          fullName: each.name,
          name: each.alpha3Code,
          code: each.callingCodes[0],
        }));
        setCountries(countriesFilterd);
      });
  }, []);

  return (
    <>
      <div className="main-wrapper">
        <Spacer height={10} />
        <div>
          <img alt="logo" src={AppLogo} className="auth-signup-logo-img" />
        </div>
        <Spacer height={20} />
        <Formik
          validateOnMount
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={async (values, helper) =>
            await signUpHandler(values, helper, history)
          }
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                <Paper elevation={3} className="auth-signup-paper">
                  <div>
                    <Typography variant="h5" component="h5">
                      Register
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2" component="h6">
                      Create a new account
                    </Typography>
                  </div>

                  <Spacer height={20} />

                  <div className="input-wrapper">
                    <div className="input_fields_div">
                      <FastField
                        name="firstName"
                        label="First name"
                        placeholder="Alice"
                        as={SmallTextField}
                      />
                    </div>

                    <div className="input_fields_div">
                      <FastField
                        name="lastName"
                        label="Last name"
                        as={SmallTextField}
                        placeholder="Whiteman"
                      />
                    </div>
                  </div>

                  <Spacer height={10} />

                  <div className="input-wrapper">
                    <div className="input_fields_div">
                      <FastField
                        name="email"
                        label="Email"
                        as={SmallTextField}
                        placeholder="user@domain.com"
                        tooltip="Use your personal email to receive verification code"
                      />
                    </div>

                    <div className="input_fields_div">
                      <FastField
                        name="phone"
                        label="Phone"
                        as={SmallTextField}
                        placeholder="No country code e.g: 30719274957"
                        tooltip="Use your personal phone since it can be used to recover account."
                      />
                    </div>
                  </div>

                  <Spacer height={10} />

                  <div className="input-wrapper">
                    <div className="input_fields_div">
                      <FastField
                        name="username"
                        label="Username"
                        as={SmallTextField}
                        placeholder="alicewhiteman99"
                      />
                    </div>

                    <div className="input_fields_div">
                      <FastField
                        name="dob"
                        as={DatePicker}
                        label="Date of birth"
                      />
                    </div>
                  </div>

                  <Spacer height={10} />

                  <div className="input-wrapper">
                    <div className="input_fields_div">
                      <FastField
                        name="password"
                        label="Password"
                        as={PasswordField}
                      />
                    </div>
                    <div className="input_fields_div">
                      <FastField
                        name="repassword"
                        as={PasswordField}
                        label="Retype Password"
                      />
                    </div>
                  </div>

                  <Spacer height={10} />

                  <div className="input-wrapper">
                    <div className="input_fields_div">
                      <FastField
                        select
                        fullWidth
                        name="country"
                        label="Country"
                        as={SmallTextField}
                      >
                        {countries.map((country: any) => (
                          <MenuItem value={country.name} key={country.name}>
                            <div className="list-item">
                              {/* <div>
                                <Avatar
                                  variant="rounded"
                                  src={country.flag}
                                  className="avatar"
                                />
                              </div> */}
                              <div>{country.fullName}</div>
                            </div>
                          </MenuItem>
                        ))}
                      </FastField>
                    </div>

                    <div className="input_fields_div">
                      <FastField
                        name="state"
                        label="State/Province"
                        as={SmallTextField}
                        placeholder="Washington"
                      />
                    </div>
                  </div>

                  <Spacer height={20} />

                  <div className="input-wrapper">
                    <div className="input_fields_div">
                      <Typography variant="subtitle2" component="p">
                        Already a member ?
                      </Typography>
                      <Link to="/login" className="link">
                        <Typography variant="subtitle1" component="p">
                          Login here.
                        </Typography>
                      </Link>
                    </div>
                    <div className="input_fields_div right">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting || !isValid}
                        variant="contained"
                      >
                        {isSubmitting ? (
                          <CircularProgress
                            className="submit-progress"
                            size={20}
                          />
                        ) : (
                          ""
                        )}{" "}
                        {isSubmitting ? "Hold tight" : "Create account"}
                      </Button>
                    </div>
                  </div>
                </Paper>
              </Slide>
            </Form>
          )}
        </Formik>

        <CopyRight />
      </div>
    </>
  );
};

export default withRouter(Signup);
