import React from "react";
import { Paper, Typography } from "@material-ui/core";

export interface QuickStatusCardProps {
  icon: any;
  title: string;
  color: string;
  light?: boolean;
  amount?: number;
  subtitle: string;
}

const QuickStatusCard: React.SFC<QuickStatusCardProps> = ({
  icon,
  light,
  title,
  subtitle,
  color,
  amount,
}) => {
  return (
    <>
      <Paper
        elevation={3}
        className="quick-status-card-paper"
        style={{ backgroundColor: light ? "#3b3f47" : "" }}
      >
        <div className="quick-status-inner-wraper">
          <div>
            <div>
              <Typography variant="subtitle1" component="h6">
                {title}
              </Typography>
              <Typography variant="subtitle2" component="p">
                {subtitle}
              </Typography>
            </div>
            <div>
              <Typography variant="h5" component="p">
                {amount ? amount : 0}
              </Typography>
            </div>
          </div>
          <div className="quick-status-icon-container">
            <div
              className="status-inner-icon-wrapper"
              style={{ backgroundColor: color }}
            >
              {icon}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default QuickStatusCard;
