import { FormikHelpers } from "formik";

import history from "../../../history";
import alertService from "../../../services/alertService";
import { ReqResetData, ResetPasswordData } from "./types/resetType";
import {
  reqResetPassword,
  resetPassword,
} from "../../../services/graphql/reset";

export async function reqReset(
  reqResetData: ReqResetData,
  helper: FormikHelpers<ReqResetData>,
  setSucess: (state: boolean) => void
): Promise<void> {
  helper.setSubmitting(true);
  const { username } = reqResetData;

  const response = await reqResetPassword(username);

  // Server failure
  if (!response) {
    helper.setSubmitting(false);
    return;
  }
  
  // Sucess
  helper.resetForm();
  helper.setSubmitting(false);
  alertService("Check your email for OTP", "success");
  setSucess(true);
}

export async function resetPasswordHandler(
  reqResetData: ResetPasswordData,
  helper: FormikHelpers<ResetPasswordData>
): Promise<void> {
  helper.setSubmitting(true);
  const { otp, password } = reqResetData;

  const response = await resetPassword(otp, password);

  // Server failure
  if (!response) {
    helper.setSubmitting(false);
    // alertService("Something went wrong", "error");
    return;
  }

  // Sucess
  helper.resetForm();
  helper.setSubmitting(false);
  alertService("Password reset sucessfull", "success");
  history.replace("/login");
}
