import React from "react";
import { Slide } from "@material-ui/core";

import "./Products.scss";
import Spacer from "../../components/Spacer";
import CopyRight from "../../components/Copyright";
import ProductBoard from "../products/ProductBoard";
import LicensesBoard from "../products/LicensesBoard";

export interface ProductsProps {}

const Products: React.SFC<ProductsProps> = () => {
  return (
    <>
      <div className="product-board-main-container">
        <div className="product-board-inner-wrapper">
          <Spacer height={30} />
          <Slide in={true} direction="left" mountOnEnter unmountOnExit>
            <div className="products-wrapper">
              <div style={{ width: "30%" }}>
                <ProductBoard />
              </div>
              <div style={{ width: "69%" }}>
                <LicensesBoard />
              </div>
            </div>
          </Slide>
          <Spacer height={10} />
          <CopyRight />
        </div>
      </div>
    </>
  );
};

export default Products;
