import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import {
  Typography,
  Button,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";

import "./Settings.scss";
import { updateInfoSchema } from "./schema";
import Spacer from "../../components/Spacer";
import authToken from "../../services/authToken";
import DatePicker from "../../components/Custom/DatePicker";
import { getUserInfo, updateUserInfo } from "../../services/graphql/userInfo";
import SmallTextField from "../../components/Custom/SmallTextField";
import {
  GetUserInfo,
  UpdateInfoValues,
} from "../../interfaces/global-interfaces";

export interface UpdateInfoProps {}

const UpdateInfo: React.SFC<UpdateInfoProps> = () => {
  const [initValues, setInitValues] = useState({
    dob: null,
    phone: "",
    country: "",
    lastName: "",
    firstName: "",
    countryState: "",
  } as GetUserInfo);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    (async () => {
      if (authToken.hasToken) {
        const result = await getUserInfo();
        if (result)
          setInitValues({
            ...result,
            dob: result.dob
              ? new Date(parseInt(result.dob as any)).toISOString()
              : null,
          });

        fetch(
          "https://restcountries.eu/rest/v2/all?fields=alpha3Code;alpha2Code;callingCodes;name"
        )
          .then((res) => res.json())
          .then((data) => {
            let countriesFilterd = data.map((each: any) => ({
              flag: `https://www.countryflags.io/${each.alpha2Code.toLowerCase()}/flat/16.png`,
              fullName: each.name,
              name: each.alpha3Code,
              code: each.callingCodes[0],
            }));
            setCountries(countriesFilterd);
          });
      }
    })();
  }, []);

  async function handleSubmit(
    values: UpdateInfoValues,
    helper: FormikHelpers<UpdateInfoValues>
  ) {
    helper.setSubmitting(true);
    await updateUserInfo(values);
    helper.setSubmitting(false);
  }

  return (
    <div>
      <div className="reset-password-title-container">
        <Typography variant="subtitle1" component="p">
          Update Personal Info
        </Typography>
      </div>
      <Spacer height={10} />
      {initValues.email ? (
        <div className="reset-password-form-wrapper">
          <Formik
            validateOnMount
            isInitialValid={false}
            onSubmit={handleSubmit}
            initialValues={initValues as any}
            validationSchema={updateInfoSchema}
          >
            {({ isSubmitting, isValid, errors }) => (
              <Form>
                <div>
                  <div className="reset-password-inner-wrapper">
                    <div className="update-info-inpput-wrapper">
                      <Field
                        name="firstName"
                        as={SmallTextField}
                        label="First Name"
                      />
                    </div>
                    <div className="update-info-inpput-wrapper">
                      <Field
                        as={SmallTextField}
                        name="lastName"
                        label="Last Name"
                      />
                    </div>
                    <div className="update-info-inpput-wrapper">
                      <Field name="dob" as={DatePicker} label="Date of birth" />
                    </div>
                  </div>

                  <Spacer height={10} />

                  <div className="reset-password-inner-wrapper">
                    <div className="update-info-inpput-wrapper">
                      <Field name="phone" as={SmallTextField} label="Phone" />
                    </div>
                    <div className="update-info-inpput-wrapper">
                      <Field
                        select
                        fullWidth
                        name="country"
                        label="Country"
                        as={SmallTextField}
                      >
                        {countries.map((country: any) => (
                          <MenuItem value={country.name} key={country.name}>
                            <div className="list-item">
                              <div>{country.fullName}</div>
                            </div>
                          </MenuItem>
                        ))}
                      </Field>
                    </div>
                    <div className="update-info-inpput-wrapper">
                      <Field
                        as={SmallTextField}
                        name="countryState"
                        label="State/Province"
                      />
                    </div>
                  </div>

                  <Spacer height={10} />

                  <div className="update-info-btn-wrapper">
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting || !isValid}
                    >
                      {isSubmitting ? (
                        <CircularProgress
                          size={15}
                          style={{ marginRight: "10px" }}
                        />
                      ) : null}
                      {isSubmitting ? "Updating ..." : "Update Info"}
                    </Button>
                  </div>
                  {/* <div>
                    <pre>{JSON.stringify(errors)}</pre>
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : null}
      <Spacer height={10} />
    </div>
  );
};

export default UpdateInfo;
