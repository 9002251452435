import React, { useState } from 'react';
import {
    IconButton,
    InputLabel,
    FormControl,
    OutlinedInput,
    InputAdornment,
    FormHelperText
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useField } from 'formik';

export interface PasswordFieldProps {
    name: string;
    value: string;
    label: string;
    onChange: (val: any) => any;
}

const PasswordField: React.SFC<PasswordFieldProps> = (props) => {
    // const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);

    const [showPassword, setShowPassword] = useState(false);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <FormControl
            fullWidth
            size="small"
            variant="outlined"
            onChange={props.onChange}
        >
            <InputLabel error={!!meta.error && meta.touched}>{props.label}</InputLabel>
            <OutlinedInput
                {...field}
                labelWidth={70}
                name={props.name}
                value={props.value}
                error={!!meta.error && meta.touched}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText error={!!meta.error && meta.touched}>{!!meta.error && meta.touched ? meta.error : ''}</FormHelperText>
        </FormControl>
    );
}

export default PasswordField;