import React, { useState } from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import {
  VpnKeyRounded,
  CheckRounded,
  DoneAllRounded,
  BlockRounded,
} from "@material-ui/icons";
import {
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import "./Admin.scss";
import { userReportSchema } from "./schema";
import Spacer from "../../components/Spacer";
import { getUserReport } from "../../services/graphql/user";
import QuickStatusCard from "../../components/QuickStatusCard";
import { UserReport } from "../../interfaces/global-interfaces";
import SmallTextField from "../../components/Custom/SmallTextField";

export interface UserReportProps {}

interface SubmitValues {
  username: string;
}

const UserReports: React.SFC<UserReportProps> = () => {
  const [report, setReport] = useState({} as UserReport);

  const initVaues = { username: "" };

  const handleClose = () => {
    setReport({ ...report, open: false });
  };

  async function handleSubmit(
    values: SubmitValues,
    helper: FormikHelpers<SubmitValues>
  ) {
    const { username } = values;
    helper.setSubmitting(true);
    const report = await getUserReport(username);
    helper.setSubmitting(false);
    if (report) {
      setReport(report);
      helper.resetForm();
    }
  }

  return (
    <>
      <div>
        <div className="admin-userreport-container">
          <Typography variant="subtitle1" component="p">
            Quick User Sales Report
          </Typography>
        </div>
        <Spacer height={10} />
        <div className="admin-userreport-form-wrapper">
          <Formik
            validateOnMount
            onSubmit={handleSubmit}
            initialValues={initVaues}
            validationSchema={userReportSchema}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <div className="admin-userreport-form-inner">
                  <div style={{ width: "49%" }}>
                    <Field
                      as={SmallTextField}
                      name="username"
                      label="Username"
                    />
                  </div>

                  <div style={{ width: "48%" }}>
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting || !isValid}
                    >
                      {isSubmitting ? (
                        <CircularProgress
                          size={15}
                          className="admin-assign-btn-progress"
                        />
                      ) : null}
                      {isSubmitting ? "Fetching..." : "Get Report"}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Spacer height={10} />
      </div>

      <Dialog onClose={handleClose} open={report.open}>
        <DialogTitle color="primary" disableTypography>
          <Typography variant="h5" component="h5">
            Quick Sales Report
          </Typography>
          <Typography variant="subtitle2" component="p">
            For User -- {report.username}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "49%" }}>
              <QuickStatusCard
                light
                title="TOTAL"
                color="#8a85ff"
                subtitle="Licenses"
                icon={<VpnKeyRounded />}
                amount={parseInt(report.total)}
              />
            </div>
            <div style={{ width: "49%" }}>
              <QuickStatusCard
                light
                title="SOLD"
                color="#3f7cff"
                subtitle="Licenses"
                icon={<CheckRounded />}
                amount={parseInt(report.sold)}
              />
            </div>
          </div>

          <Spacer height={8} />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "49%" }}>
              <QuickStatusCard
                light
                color="#32ad48"
                title="ACTIVATED"
                subtitle="By client"
                icon={<DoneAllRounded />}
                amount={parseInt(report.active)}
              />
            </div>
            <div style={{ width: "49%" }}>
              <QuickStatusCard
                light
                color="coral"
                title="BLOCKED"
                subtitle="Licenses"
                icon={<BlockRounded />}
                amount={parseInt(report.blocked)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserReports;
