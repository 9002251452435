import { AlertPayload, AlertActionType, Alert } from "../interfaces/global-interfaces";




export default function notificationReducer(
    state: AlertPayload = { message: '', severity: 'error', open: false },
    action: AlertActionType
) {
    // const { message, severity } = action.payload;
    switch (action.type) {
        case Alert.Close:
            state = {
                ...state,
                open: false
            } as AlertPayload
            return state;
        case Alert.Open:
            state = {
                ...state,
                message: action.payload.message,
                severity: action.payload.severity,
                open: true
            } as AlertPayload
            return state;
        default:
            return state;
    }
}
