import React from "react";
import { connect } from "react-redux";
import { Typography, CircularProgress } from "@material-ui/core";
import {
  VpnKeyRounded,
  DoneAllRounded,
  BlockRounded,
  CheckRounded,
} from "@material-ui/icons";

import "./Dashboard.scss";
import Spacer from "../../components/Spacer";
import QuickStatusCard from "../../components/QuickStatusCard";
import { QuickLookPayload } from "../../interfaces/global-interfaces";

export interface QuickStatusProps {
  total?: number;
  sold?: number;
  active?: number;
  blocked?: number;
  fetching: boolean;
}

const QuickStatus: React.SFC<QuickStatusProps> = ({
  total,
  sold,
  active,
  blocked,
  fetching,
}) => {
  return (
    <>
      <Spacer height={20} />
      <div className="quickstatus-title-progress-con">
        <div>
          <Typography variant="h6" component="h5">
            Quick Lookup
          </Typography>
        </div>
        <div>
          {fetching ? (
            <CircularProgress
              size={15}
              color="primary"
              className="dashboard-activity-progress"
            />
          ) : null}
        </div>
      </div>
      <Spacer height={5} />
      <div className="quick-card-container">
        <div className="quick-card-wrapper">
          <QuickStatusCard
            title="TOTAL"
            amount={total}
            color="#8a85ff"
            subtitle="Licenses"
            icon={<VpnKeyRounded />}
          />
        </div>
        <div className="quick-card-wrapper">
          <QuickStatusCard
            title="SOLD"
            amount={sold}
            color="#3f7cff"
            subtitle="Licenses"
            icon={<CheckRounded />}
          />
        </div>
        <div className="quick-card-wrapper">
          <QuickStatusCard
            amount={active}
            color="#32ad48"
            title="ACTIVATED"
            subtitle="By client"
            icon={<DoneAllRounded />}
          />
        </div>
        <div className="quick-card-wrapper">
          <QuickStatusCard
            amount={blocked}
            color="coral"
            title="BLOCKED"
            subtitle="By moderator"
            icon={<BlockRounded />}
          />
        </div>
      </div>
    </>
  );
};

function mapPropsToState(state: any, props: QuickStatusProps) {
  const {
    total,
    sold,
    active,
    blocked,
  } = state.quicklookup as QuickLookPayload;
  return {
    sold,
    total,
    active,
    blocked,
  };
}

export default connect(mapPropsToState, null)(QuickStatus);
