import React, { useEffect, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { Backdrop, CircularProgress } from "@material-ui/core";

import "./App.scss";
import Routes from "./Routes";
import history from "./history";
import store from "./store/store";
import authToken from "./services/authToken";
import { TokenType } from "./interfaces/global-interfaces";
import refreshAccessToken from "./services/graphql/refreshToken";

export interface AppProps {}

const App: React.SFC<AppProps> = () => {
  const location = history.location;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requiredRefresh = localStorage.getItem(TokenType.RefreshToken);
    (async () => {
      history.push("/login");
      if (requiredRefresh) {
        setLoading(true);
        try {
          await refreshAccessToken();
          const accessToken = authToken.getToken();
          if (accessToken.length > 0) {
            history.push(location.pathname || "/");
          } else {
            history.push("/login");
          }
        } catch (err) {
          history.push("/login");
        }
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ReduxProvider store={store}>
        <Routes />
        <Backdrop open={loading} className="refresh-backdrop">
          <div className="main-wrapper">
            <div className="refresh-progress-container">
              <CircularProgress className="refresh-progress" size={18} />
            </div>
            <div className="refresh-progress-container">
              <p className="refresh-token-text">Restoring Session</p>
            </div>
          </div>
        </Backdrop>
      </ReduxProvider>
    </>
  );
};

export default App;
