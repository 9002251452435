import { Client } from 'urql/core';

import store from '../../store/store';
import feedActions from '../../actions/feedsAction';
import { API_SERVER } from '../../../constants.json';
import licenseAction from '../../actions/getLicenseAction'
import activityLogs from '../../actions/activityLogsAction'

class CreateClient {
    private client: Client;
    constructor() {
        this.client = this.createClient();
    }

    private createClient(): Client {
        return new Client({
            url: API_SERVER + "graphql",
            requestPolicy: 'network-only'
        });
    }

    public resetClient(): void {
        store.dispatch(feedActions([]));
        store.dispatch(licenseAction([]));
        store.dispatch(activityLogs([]));
        this.client = this.createClient();
    }

    public getClient(): Client {
        return this.client;
    }
}

export default new CreateClient();

