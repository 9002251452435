import React from 'react';
import { useField } from 'formik';
import { TextField, Tooltip } from '@material-ui/core';

export interface SmallTextFieldProps {
    tooltip: string;
    value: any;
    name: string;
    label: string;
    onChange: any;
    placeholder: string;
}

const SmallTextField: React.SFC<SmallTextFieldProps> = (props) => {
    const [field, meta] = useField(props);
    return (
        <Tooltip title={props.tooltip || ''}>
            <TextField
                {...field}
                {...props}
                fullWidth
                size="small"
                variant="outlined"
                autoComplete="off"
                error={!!meta.error && meta.touched}
                helperText={!!meta.error && meta.touched ? meta.error : ''}
            />
        </Tooltip>
    );
}

export default SmallTextField;