import React from "react";
import { Link } from "react-router-dom";
import { Form, Formik, Field } from "formik";
import {
  Slide,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";

import { schema2 } from "./immutable/schema";
import Spacer from "../../../components/Spacer";
import { step2 } from "./immutable/intialValues";
import AppLogo from "../../../../assets/logo.png";
import Copyright from "../../../components/Copyright";
import { resetPasswordHandler } from "./resetHandler";
import SmallTextField from "../../../components/Custom/SmallTextField";
import SmallPasswordField from "../../../components/Custom/PasswordField";

export interface RequestOtpProps {
}

const RequestOtp: React.SFC<RequestOtpProps> = () => {
  return (
    <>
      <div className="main-wrapper">
        <Spacer height={10} />
        <div>
          <img alt="logo" src={AppLogo} className="auth-signin-logo-img" />
        </div>
        <Spacer height={20} />

        <Formik
          validateOnMount
          initialValues={step2}
          validationSchema={schema2}
          onSubmit={async (values, helper) => await resetPasswordHandler(values, helper)}
        >
          {({ isValid, isSubmitting }) => (
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
              <Paper elevation={3} className="auth-signin-paper">
                <Form>
                  <div>
                    <Typography variant="h5" component="h5">
                      We have sended
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2" component="h6">
                      you email with verification code
                    </Typography>
                  </div>

                  <Spacer height={25} />

                  <div>
                    <div>
                      <Field
                        name="otp"
                        as={SmallTextField}
                        label="Enter OTP"
                        placeholder="Check your email"
                      />
                    </div>
                    <Spacer height={15} />
                    <div>
                      <Field
                        name="password"
                        as={SmallPasswordField}
                        label="Enter new password"
                        placeholder="Your password"
                      />
                    </div>
                    <Spacer height={15} />
                    <div>
                      <Field
                        name="rePassword"
                        as={SmallPasswordField}
                        label="Retype password"
                        placeholder="Retype it"
                      />
                    </div>
                  </div>

                  <Spacer height={25} />

                  <div className="input-wrapper">
                    <div className="input_fields_div">
                      <Link to="/login" className="link">
                        <Typography variant="subtitle1" component="p">
                          Back to Login
                        </Typography>
                      </Link>
                    </div>

                    <div className="input_fields_div right">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting || !isValid}
                        variant="contained"
                      >
                        {isSubmitting ? (
                          <CircularProgress
                            className="submit-progress"
                            size={20}
                          />
                        ) : (
                          ""
                        )}{" "}
                        {isSubmitting ? "Hold tight" : "Reset"}
                      </Button>
                    </div>
                  </div>
                </Form>
              </Paper>
            </Slide>
          )}
        </Formik>
        <Copyright />
      </div>
    </>
  );
};

export default RequestOtp;
