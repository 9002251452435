import React from "react";
import { Typography } from "@material-ui/core";

import "./Component.scss";
import Spacer from "./Spacer";
import { COMPANY_EMAIL, COMPANY_URL } from "../../constants.json";

export interface CopyRightProps {
  className?: string;
}

const CopyRight: React.SFC<CopyRightProps> = ({ className }) => {
  return (
    <div className="copy-right-container">
      <Spacer height={10} />
      <a href={`mailto: ${COMPANY_EMAIL}`} className="link">
        <Typography component="p" className="center" variant="subtitle2">
          {COMPANY_EMAIL}
        </Typography>
      </a>
      <Typography variant="subtitle2" component="p">
        Copyright © 2020 {COMPANY_URL}
      </Typography>

      <Spacer height={10} />
    </div>
  );
};

export default CopyRight;
