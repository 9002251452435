import gql from 'graphql-tag';
import { History } from 'history';
import { OperationContext } from 'urql/core';

import gqlClient from './gqlClient';
import authToken from '../authToken';
import alertService from '../alertService';
import { TokenType } from '../../interfaces/global-interfaces';

export async function requestVerification(): Promise<boolean> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: 'network-only'
    }

    const query = gql`
        {
            requestVerification
        }
    `

    const response = await client.query(query, {}, options)
        .toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure', 'error');
            return false;
        }
        alertService(response.error.graphQLErrors[0].message, 'error');
        return false;
    }

    return response.data.requestVerification as boolean;
}


export async function verifyAccount(otp: string, history: History) {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: 'network-only'
    }

    const query = gql`
        mutation($otp: String!){
            verifyAccount(otp: $otp){
                verified
            }
        }
    `

    const response = await client.query(query, { otp }, options)
        .toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure', 'error');
            return;
        }

        alertService(response.error.graphQLErrors[0].message, 'error');
        return;
    }

    if (response.data.verifyAccount as boolean) {
        alertService('Account verified', 'success');
        localStorage.removeItem(TokenType.AccessToken);
        localStorage.removeItem(TokenType.RefreshToken);
        history.push('/login')
    } else {
        alertService('Account not verified, try latter', 'warning');
    }
}