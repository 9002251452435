import gql from 'graphql-tag';
import { OperationContext } from 'urql/core';

import gqlClient from './gqlClient';
import authToken from '../authToken';
import alertService from '../alertService';


export default async function blockUnblockUser(username: string, block: boolean): Promise<boolean> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: "network-only"
    }

    const blockQuery = gql`
        mutation($username: String!) {
            blockUser(username: $username){
                blocked
            }
        }
    `
    const unBlockQuery = gql`
        mutation($username: String!) {
            unBlockUser(username: $username){
                blocked
            }
        }
    `

    const response = await client.query(block ? blockQuery : unBlockQuery, { username }, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure', 'error');
            return false;
        }
        alertService(response.error.graphQLErrors[0].message, 'error');
        return false;
    }

    alertService(`User ${username} is ${block ? "blocked" : "unBlocked"}`, block ? 'warning' : 'info');
    return true;
}