import React, { useEffect } from "react";
import { Slide, Typography, Paper } from "@material-ui/core";

import "./Admin.scss";
import UserReports from "./UserReport";
import Spacer from "../../components/Spacer";
import AssignLicenses from "./AssignLicenses";
import authToken from "../../services/authToken";
import BlockUnBlockUser from "./BlockUnBlockUser";
import AddOrRemoveFeeds from "./AddOrRemoveFeeds";
import AddRemoveProduct from "./AddRemoveProducts";
import CopyRight from "../../components/Copyright";
import PermentUserRemoval from "./PermentUserRemoval";
import adminApihandler from "../../services/graphql/admin";

export interface AdminProps {}

const Admin: React.SFC<AdminProps> = () => {
  useEffect(() => {
    if (authToken.hasToken) adminApihandler(false);
  }, []);

  return (
    <>

      <div className="admin-panel-container">
        <div className="admin-panel-inner-wrapper">
          {/* <NavMenu /> */}
          <Spacer height={10} />
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <div>
              <div>
                <Typography variant="h6" component="h5">
                  Admin (Super User)
                </Typography>
              </div>
              <div>
                <Paper className="admin-panel-paper">
                  <div className="admin-panel-inner-container">
                    <AssignLicenses />
                    <BlockUnBlockUser />
                    <AddOrRemoveFeeds />
                    <AddRemoveProduct />
                    <UserReports />
                    <PermentUserRemoval />
                  </div>
                </Paper>
              </div>
            </div>
          </Slide>
        </div>
      </div>
      <CopyRight />
    </>
  );
};

export default Admin;
