import React from "react";
import { connect } from "react-redux";
import { DeleteRounded } from "@material-ui/icons";
import { Field, Form, Formik, FormikHelpers } from "formik";
import {
  Typography,
  Button,
  CircularProgress,
  Paper,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  List,
} from "@material-ui/core";

import { addProductSchema } from "./schema";
import Spacer from "../../components/Spacer";
import SmallTextField from "../../components/Custom/SmallTextField";
import { ProductPayload } from "../../interfaces/global-interfaces";
import addProduct, {
  removeProduct,
} from "../../services/graphql/addRemoveProduct";

export interface AssignLicensesProps {
  products?: ProductPayload[];
}

interface SubmitValues {
  name: string;
  productId: string;
}

const AddRemoveProducts: React.SFC<AssignLicensesProps> = ({ products }) => {
  const initVaues = { name: "", productId: "" };

  async function handleSubmit(
    values: SubmitValues,
    helper: FormikHelpers<SubmitValues>
  ) {
    const { name, productId } = values;
    helper.setSubmitting(true);
    const done = await addProduct(name, productId);
    helper.setSubmitting(false);
    if (done) helper.resetForm();
  }

  async function handleProductDelete(productId: string) {
    await removeProduct(productId);
  }

  return (
    <div>
      <div className="admin-product-title">
        <Typography variant="subtitle1" component="p">
          Add / Remove Products
        </Typography>
      </div>
      <Spacer height={10} />
      <div className="admin-product-main-container">
        <div className="admin-product-inner-wrapper">
          <Formik
            validateOnMount
            onSubmit={handleSubmit}
            initialValues={initVaues}
            validationSchema={addProductSchema}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <div className="admin-product-field">
                  <Field as={SmallTextField} name="name" label="Product Name" />
                </div>
                <Spacer height={10} />
                <div className="admin-product-field">
                  <Field
                    name="productId"
                    label="ProductId"
                    as={SmallTextField}
                  />
                </div>
                <Spacer height={10} />
                <div className="admin-product-field">
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        size={15}
                        className="admin-product-progress"
                      />
                    ) : null}
                    {isSubmitting ? "Adding..." : "Add Product"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="admin-product-view-wrapper">
          <Paper elevation={3} className="admin-product-view-paper">
            <div className="admin-product-inner-list-wrapper">
              <List disablePadding>
                {products?.map((singleProduct) => (
                  <ListItem
                    button
                    key={singleProduct.id}
                    ContainerComponent="div"
                  >
                    <ListItemText primary={singleProduct.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        size="medium"
                        className="admin-product-del-icon"
                        onClick={() =>
                          handleProductDelete(singleProduct.productId)
                        }
                      >
                        <DeleteRounded fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </Paper>
        </div>
      </div>
      <Spacer height={10} />
    </div>
  );
};

function mapStateToProps(state: any, props: any) {
  const products = state.products;
  return {
    products,
  };
}

export default connect(mapStateToProps, null)(AddRemoveProducts);
