import React from "react";
import { Typography, Paper, Slide } from "@material-ui/core";

import "./Settings.scss";
import UpdateInfo from "./UpdateInfo";
import ResetPassword from "./ResetPassword";
import Spacer from "../../components/Spacer";
import CopyRight from "../../components/Copyright";
import AccountVerification from "./AccountVerification";

export interface SettingsProps {}

const Settings: React.SFC<SettingsProps> = () => {
  return (
    <>
      <div className="settings-main-wrapper">
        <div className="settings-inner-wrapper">
          <Spacer height={25} />
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <div>
              <div>
                <Typography variant="h6" component="h5">
                  Settings
                </Typography>
              </div>
              <div>
                <Paper className="settings-paper">
                  <div className="settings-paper-inner">
                    <AccountVerification />
                    <ResetPassword />
                    <UpdateInfo />
                  </div>
                </Paper>
              </div>
            </div>
          </Slide>
        </div>
        <Spacer height={10} />
      </div>
      <CopyRight />
    </>
  );
};

export default Settings;
