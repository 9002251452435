import React from "react";
import { History } from "history";
import { Formik, Form, Field } from "formik";
import { Link, withRouter } from "react-router-dom";
import {
  Paper,
  Slide,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";

import "./Signin.scss";
import schema from "./immutable/schema";
import signInHandler from "./signinHandler";
import Spacer from "../../../components/Spacer";
import AppLogo from "../../../../assets/logo.png";
import initialValues from "./immutable/intialValues";
import Copyright from "../../../components/Copyright";
import PasswordField from "../../../components/Custom/PasswordField";
import SmallTextField from "../../../components/Custom/SmallTextField";

export interface SigninProps {
  history: History;
}

const Signin: React.SFC<SigninProps> = ({ history }) => {
  return (
    <>
      <div className="main-wrapper">
        <Spacer height={10} />
        <div>
          <img alt="logo" src={AppLogo} className="auth-signin-logo-img" />
        </div>
        <Spacer height={20} />

        <Formik
          validateOnMount
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={async (values, helper) =>
            await signInHandler(values, helper, history)
          }
        >
          {({ isValid, isSubmitting }) => (
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
              <Paper elevation={3} className="auth-signin-paper">
                <Form>
                  <div>
                    <Typography variant="h5" component="h5">
                      Login
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2" component="h6">
                      Sign into your dashboard
                    </Typography>
                  </div>

                  <Spacer height={25} />

                  <div>
                    <div>
                      <Field
                        name="username"
                        label="Username or Email"
                        placeholder="alicewhiteman99"
                        as={SmallTextField}
                      />
                    </div>

                    <Spacer height={15} />

                    <div>
                      <Field
                        name="password"
                        label="Password"
                        as={PasswordField}
                      />
                    </div>
                    <div>
                      <Link to="reset" className="link">
                        <Typography variant="subtitle2" component="p">
                          Forget Password ?
                        </Typography>
                      </Link>
                    </div>
                  </div>

                  <Spacer height={25} />

                  <div className="input-wrapper">
                    <div className="input_fields_div">
                      <Typography variant="subtitle2" component="p">
                        Not a member ?
                      </Typography>
                      <Link to="/signup" className="link">
                        <Typography variant="subtitle1" component="p">
                          Register here.
                        </Typography>
                      </Link>
                    </div>

                    <div className="input_fields_div right">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting || !isValid}
                        variant="contained"
                      >
                        {isSubmitting ? (
                          <CircularProgress
                            className="submit-progress"
                            size={20}
                          />
                        ) : (
                          ""
                        )}{" "}
                        {isSubmitting ? "Hold tight" : "Login"}
                      </Button>
                    </div>
                  </div>
                </Form>
              </Paper>
            </Slide>
          )}
        </Formik>
        <Copyright />
      </div>
    </>
  );
};

export default withRouter(Signin);
