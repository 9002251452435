import { License, LicenseAction, LicensesWithTotal } from "../interfaces/global-interfaces";

const intiState = { total: 0, licenses: [] };

export default function licenesReducer(state: LicensesWithTotal = intiState , action: License) {
    switch (action.type) {
        case LicenseAction.Update:
            return {
                ...action.payload
            } as LicensesWithTotal;
        default:
            return state;
    }
}