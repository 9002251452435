import { SignUpInitialValues } from "../types/types";

export default {
    email: '',
    state: '',
    phone: '',
    username: '',
    password: '',
    lastName: '',
    firstName: '',
    dob: '',
    country: '',
} as SignUpInitialValues;