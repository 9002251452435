import { QuickLookAction, QuickLook, QuickLookPayload } from "../interfaces/global-interfaces";

export default function quickLookAction(
    total: number,
    sold: number,
    active: number,
    blocked: number
) {
    return {
        type: QuickLookAction.Update,
        payload: {
            sold,
            total,
            active,
            blocked
        } as QuickLookPayload
    } as QuickLook
}