import gql from 'graphql-tag';
import { OperationContext } from 'urql/core';

import gqlClient from './gqlClient';
import authToken from '../authToken';
import store from '../../store/store';
import alertService from '../alertService';
import feedAction from '../../actions/feedsAction';
import productAction from '../../actions/productActions';

export default async function getAllFeeds(cache?: boolean): Promise<void> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: "network-only"
    }

    const query = gql`
        query {
            getFeeds {
                id
                title
                timestamp
                description
            }

            getAllProducts{
                id
                name
                productId   
            }
        }
    `

    const response = await client.query(query, {}, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure.', 'error');
            return;
        }

        alertService(response.error.graphQLErrors[0].message, 'error');
        return;
    }

    const feeds = response.data.getFeeds;
    const products = response.data.getAllProducts;

    store.dispatch(feedAction(feeds));
    store.dispatch(productAction(products));
}