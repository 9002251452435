// import logger from 'redux-logger';
import { createStore } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from '../reducers/combineReducers';

// const composeEnhancers = composeWithDevTools();

/**
 * @description Global store for applciation
 */
// export default createStore(reducers as any, {}, applyMiddleware(logger));

export default createStore(reducers as any, {});