import { Alert, AlertSeverity, AlertActionType, AlertPayload } from "../interfaces/global-interfaces";

export default function notificationAction(status: Alert, message?: string, severity?: AlertSeverity) {
    return {
        type: status,
        payload: {
            message,
            severity,
            open: status === Alert.Open ? true : false
        } as AlertPayload
    } as AlertActionType
}