import gql from 'graphql-tag';
import { OperationContext } from 'urql/core';

import gqlClient from './gqlClient';
import authToken from '../authToken';
import store from '../../store/store';
import alertService from '../alertService';
import feedAction from '../../actions/feedsAction';


export async function deleteFeed(id: string): Promise<void> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: 'network-only'
    }

    const query = gql`
        mutation($id: ID!){
            delFeed(id:$id){
                id
            }
        }
    `

    const response = await client.mutation(query, { id }, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure.', 'error');
            return;
        }

        alertService(response.error.graphQLErrors[0].message, 'error');
        return;
    }

    await getAllFeeds(false);
    alertService('Feed deleted', 'info');
}


export async function addFeed(title: string, description: string): Promise<boolean> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: 'network-only'
    }

    const query = gql`
        mutation($title: String!, $description: String!){
            setFeed(title: $title, description: $description){
                id
            }
        }
    `

    const response = await client.mutation(query, { title, description }, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure.', 'error');
            return false;
        }

        alertService(response.error.graphQLErrors[0].message, 'error');
        return false;
    }

    await getAllFeeds(false);
    alertService('Feed added', 'success');
    return true;
}


export async function getAllFeeds(cache?: boolean): Promise<void> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: !cache ? "network-only" : "cache-and-network"
    }

    const query = gql`
        query {
            getFeeds {
                id
                title
                timestamp
                description
            }
        }
    `

    const response = await client.query(query, {}, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure.', 'error');
            return;
        }

        alertService(response.error.graphQLErrors[0].message, 'error');
        return;
    }
    const feeds = response.data.getFeeds;
    store.dispatch(feedAction(feeds));
}