import { combineReducers } from 'redux';

import feeds from './feedsReducer'
import products from './productReducer';
import licenses from './licensesReducer'
import notification from './notification';
// import appTheme from './changeThemeReducer';
import quicklookup from './quicklookReducer';
import activityLogs from './activityLogsReducer';

export default combineReducers({
    feeds,
    licenses,
    products,
    quicklookup,
    notification,
    activityLogs
});