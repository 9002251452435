import { connect } from "react-redux";
import React, { useState } from "react";
// import ReactMarkDown from "react-markdown";
import MarkDown from "markdown-to-jsx";
import { FeedPayload } from "../interfaces/global-interfaces";
import { NotificationsActiveRounded, DeleteRounded } from "@material-ui/icons";
import {
  Paper,
  Button,
  Dialog,
  ListItem,
  IconButton,
  Typography,
  DialogTitle,
  ListItemText,
  DialogActions,
  DialogContent,
  ListItemSecondaryAction,
} from "@material-ui/core";

import "./Container.scss";
import { deleteFeed } from "../services/graphql/feeds";

export interface FeedsProps {
  light?: boolean;
  admin: boolean;
  feeds?: FeedPayload[];
  heightOffset: number;
}

const Feeds: React.SFC<FeedsProps> = ({
  feeds,
  admin,
  light,
  heightOffset,
}) => {
  const [dialog, setDialog] = useState({
    title: "",
    open: false,
    description: "",
  });

  const handleClickOpen = (title: string, description: string) => {
    setDialog({ open: true, title, description });
  };
  const handleClose = () => {
    setDialog({ ...dialog, open: false });
  };

  return (
    <>
      <Paper
        elevation={3}
        style={{ padding: "0", backgroundColor: light ? "#3b3f47" : "" }}
      >
        <div
          style={{
            minHeight: `calc(100vh - ${heightOffset}px)`,
            maxHeight: `calc(100vh - ${heightOffset}px)`,
            overflow: "auto",
          }}
        >
          {feeds && feeds?.length > 0 ? (
            feeds?.map((eachFeed) => (
              <ListItem
                button
                key={eachFeed.timestamp}
                ContainerComponent="div"
                onClick={() =>
                  handleClickOpen(eachFeed.title, eachFeed.description)
                }
              >
                <ListItemText
                  primary={eachFeed.title}
                  secondary={`${new Date(
                    parseInt(eachFeed.timestamp)
                  ).toLocaleDateString()} -- ${new Date(
                    parseInt(eachFeed.timestamp)
                  ).toLocaleTimeString()}`}
                />

                {admin ? (
                  <ListItemSecondaryAction>
                    <IconButton
                      size="medium"
                      onClick={() => deleteFeed(eachFeed.id)}
                    >
                      <DeleteRounded fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                ) : null}
              </ListItem>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: `calc(100vh - ${heightOffset}px)`,
              }}
            >
              <div style={{ textAlign: "center" }}>
                <NotificationsActiveRounded
                  style={{ opacity: 0.5 }}
                  fontSize="small"
                />
                <Typography variant="subtitle2" component="p">
                  No Feeds
                </Typography>
              </div>
            </div>
          )}
        </div>
      </Paper>

      <Dialog onClose={handleClose} open={dialog.open} fullWidth>
        <DialogTitle color="primary" disableTypography>
          <Typography variant="h5" component="h5">
            {dialog.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <MarkDown color="">{dialog.description}</MarkDown>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function mapStateToProps(state: any, props: any) {
  const feeds = state.feeds as FeedPayload[];
  return {
    feeds,
  };
}

export default connect(mapStateToProps, null)(Feeds);
