import copy from "copy-to-clipboard";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { green, red, orange } from "@material-ui/core/colors";
import {
  DoneRounded,
  BlockRounded,
  RefreshRounded,
} from "@material-ui/icons";
import {
  Paper,
  Tooltip,
  MenuItem,
  ListItem,
  TextField,
  Typography,
  IconButton,
  ListItemText,
  CircularProgress,
  ListItemSecondaryAction,
} from "@material-ui/core";

import "./Products.scss";
import Spacer from "../../components/Spacer";
import authToken from "../../services/authToken";
import alertService from "../../services/alertService";
import toggleSold from "../../services/graphql/LicenseSold";
import fetchLicenses from "../../services/graphql/getLicenses";
import { LICENSEBOARD_PAGE_SIZE } from "../../../constants.json";
import toggleBlock from "../../services/graphql/licenseBlockToggle";
import {
  LicensePayload,
  OrderLicenseBy,
} from "../../interfaces/global-interfaces";

export interface LicensesDashboardProps {
  total?: number;
  licensess?: LicensePayload[];
}

const LicensesDashboard: React.SFC<LicensesDashboardProps> = ({
  total,
  licensess,
}) => {
  const pageSize = LICENSEBOARD_PAGE_SIZE; // min 10 max 100
  const { productId } = useParams();
  const [page, setPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLicenses] = useState(licensess);
  const [fetching, setFetching] = useState(false);
  const [sortOrder, setSortOrder] = useState(OrderLicenseBy.Sold);

  useEffect(() => {
    // updateBoard(productId, sortOrder, page, pageSize);
    if (productId && authToken.hasToken) {
      setFetching(true);
      fetchLicenses(pageSize, page - 1, productId, sortOrder).then(() => {
        setLicenses([]);
        setFetching(false);
      });
    } else {
      setLicenses([]);
    }
  }, [productId, sortOrder, page, pageSize]);

  /**
   * @param event Select event
   * @description Set sord order for License Board
   */
  function setOrderBy(event: any) {
    const order: OrderLicenseBy = event.target.value;
    setSortOrder(order);
  }

  /**
   * @description Update license board
   */
  function updateBoard() {
    if (productId) {
      setFetching(true);
      fetchLicenses(pageSize, page - 1, productId, sortOrder).then((data) => {
        setLicenses([]);
        setFetching(false);
      });
    } else {
      setLicenses([]);
    }
  }

  /**
   * @description reset license board
   */
  function resetLicenses() {
    updateBoard();
  }

  /**
   * @param id license id:string
   * @description Takes license id and toggle mark as blocked or unBlocked
   */
  async function blockLicense(id: string) {
    setFetching(true);
    const updateLicenses = licensess?.map((eachKey) => {
      if (eachKey.id === id) {
        eachKey.blocked = !eachKey.blocked;
        return eachKey;
      }
      return eachKey;
    });

    setLicenses(updateLicenses);

    const done = await toggleBlock(id);
    if (!done) {
      const oldState = licensess?.map((eachKey) => {
        if (eachKey.id === id) {
          eachKey.blocked = !eachKey.blocked;
          return eachKey;
        }
        return eachKey;
      });
      setLicenses(oldState);
    }
    setFetching(false);
  }

  /**
   * @param id license id:string
   * @description Takes license id and toggle mark as sold or unsold
   */
  async function soldLicense(id: string) {
    setFetching(true);
    const updateLicenses = licensess?.map((eachKey) => {
      if (eachKey.id === id) {
        eachKey.sold = !eachKey.sold;
        return eachKey;
      }
      return eachKey;
    });

    setLicenses(updateLicenses);

    const done = await toggleSold(id);
    if (!done) {
      const oldState = licensess?.map((eachKey) => {
        if (eachKey.id === id) {
          eachKey.sold = !eachKey.sold;
          return eachKey;
        }
        return eachKey;
      });
      setLicenses(oldState);
    }
    setFetching(false);
  }

  /**
   * @param key license key
   * @description copy license key to clipboard
   */
  function copyLicenseKey(key: string) {
    copy(key);
    alertService("License copied to clipboard", "info");
  }

  return (
    <>
      <div className="license-board-wrapper">
        <div>
          <Typography variant="h6" component="h5">
            Licenses
          </Typography>
        </div>
        <div>
          {fetching ? (
            <CircularProgress className="loading-progress" size={15} />
          ) : (
            <Tooltip title="Refresh license">
              <IconButton size="small" onClick={resetLicenses}>
                <RefreshRounded color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className="total-license">
          <Typography variant="subtitle2" component="p">
            Total: {total}
          </Typography>
        </div>
      </div>
      <Paper elevation={3}>
        <div>
          <div className="license-inner-wrapper">
            <div>
              {licensess?.map((eachLicense, index) => (
                <ListItem
                  button
                  key={eachLicense.id}
                  ContainerComponent="div"
                  onClick={() => copyLicenseKey(eachLicense.key)}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#353a4460" : "",
                  }}
                >
                  <Tooltip
                    title={
                      eachLicense.activationDate
                        ? `Activated by ${eachLicense.username} on ${new Date(
                            parseInt(eachLicense.activationDate)
                          ).toLocaleDateString()} -- ${new Date(
                            parseInt(eachLicense.activationDate)
                          ).toLocaleTimeString()}`
                        : ""
                    }
                  >
                    <ListItemText>
                      <Typography
                        variant="subtitle1"
                        color={
                          eachLicense.activationDate ? "primary" : "initial"
                        }
                      >
                        {eachLicense.key.substr(0, 23)}...{" "}
                        <span
                          className="expiration-days"
                          style={{ color: orange[500] }}
                        >
                          {eachLicense.expireDays} Day(s)
                        </span>
                      </Typography>
                    </ListItemText>
                  </Tooltip>
                  <ListItemSecondaryAction>
                    <Tooltip
                      title={eachLicense.sold ? "Sold out" : "Not Sold Yet"}
                    >
                      <IconButton
                        color="primary"
                        style={{ color: eachLicense.sold ? green[500] : "" }}
                        onClick={() => soldLicense(eachLicense.id)}
                      >
                        <DoneRounded fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="Copy License">
                      <IconButton
                        color="primary"
                        onClick={() => copyLicenseKey(eachLicense.key)}
                      >
                        <FilterNoneRounded fontSize="small" />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip
                      title={eachLicense.blocked ? "Blocked" : "Not Blocked"}
                    >
                      <IconButton
                        color="primary"
                        style={{ color: eachLicense.blocked ? red[500] : "" }}
                        onClick={() => blockLicense(eachLicense.id)}
                      >
                        <BlockRounded fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </div>
          </div>
          <Spacer height={10} />
          <div className="license-board-footer">
            <div style={{ width: "70%" }}>
              <Pagination
                page={page}
                shape="rounded"
                siblingCount={1}
                variant="outlined"
                onChange={(e, val) => setPage(val)}
                count={Math.ceil((total || 0) / pageSize) || 0}
              />
            </div>
            <div className="sortby-wrapper">
              <TextField
                select
                fullWidth
                size="small"
                label="Sort by"
                variant="outlined"
                onChange={setOrderBy}
                defaultValue={sortOrder}
              >
                <MenuItem key="none" value={OrderLicenseBy.None}>
                  None
                </MenuItem>
                <MenuItem key="sold" value={OrderLicenseBy.Sold}>
                  Sold
                </MenuItem>
                <MenuItem key="blocked" value={OrderLicenseBy.Blocked}>
                  Blocked
                </MenuItem>
                <MenuItem key="active" value={OrderLicenseBy.Active}>
                  Activated
                </MenuItem>
              </TextField>
            </div>
          </div>
          <Spacer height={10} />
        </div>
      </Paper>
    </>
  );
};

function mapStateToProps(state: any, _: any) {
  const total: number = state.licenses.total;
  const licensess: LicensePayload[] = state.licenses.licenses;
  return {
    licensess,
    total,
  };
}

export default connect(mapStateToProps, null)(LicensesDashboard);
