import gql from 'graphql-tag';
import { History } from 'history';
import { OperationContext } from 'urql/core';

import gqlClient from './gqlClient';
import authToken from '../authToken';
import alertService from '../alertService';
import { TokenType } from '../../interfaces/global-interfaces';


export default async function changePassword(password: string, history: History): Promise<void> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: "network-only"
    }

    const query = gql`
        mutation($password: String!){
            changePassword(password: $password)
        }
    `

    const response = await client.mutation(query, { password }, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure', 'error');
            return;
        }
        alertService(response.error.graphQLErrors[0].message, 'error');
        return;
    }

    const passwordStatus = response.data.changePassword as boolean;

    alertService(
        passwordStatus ? 'Password Updated.' : 'Fail to update password',
        passwordStatus ? 'success' : 'warning'
    );
    if (passwordStatus) {
        localStorage.removeItem(TokenType.AccessToken);
        localStorage.removeItem(TokenType.RefreshToken);
        history.push('/login');
    }
}