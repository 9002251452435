import React from "react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import {
  Typography,
  Button,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";

import "./Admin.scss";
import Spacer from "../../components/Spacer";
import { permentUserDelSchema } from "./schema";
import SmallTextField from "../../components/Custom/SmallTextField";
import { permentUserRemoval } from "../../services/graphql/user";

export interface AssignLicensesProps {}

interface SubmitValues {
  agree: boolean;
  username: string;
}

const PermentUserRemoval: React.SFC<AssignLicensesProps> = () => {
  const initVaues = { agree: false, username: "" };

  async function handleSubmit(
    values: SubmitValues,
    helper: FormikHelpers<SubmitValues>
  ) {
    const { username } = values;
    helper.setSubmitting(true);
    const done = await permentUserRemoval(username);
    helper.setSubmitting(false);
    if (done) helper.resetForm();
  }

  return (
    <div>
      <div className="admin-puser-main-contianer">
        <Typography variant="subtitle1" component="p">
          <span role="img" aria-label="warrning">
            ⚠️
          </span>{" "}
          Permently Delete User And All Its Data
        </Typography>
      </div>
      <Spacer height={10} />
      <div className="admin-puser-formwrapper">
        <Formik
          validateOnMount
          onSubmit={handleSubmit}
          initialValues={initVaues}
          validationSchema={permentUserDelSchema}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <div className="admin-puser-inner-wrapper">
                <div className="admin-puser-field-con">
                  <Field as={SmallTextField} name="username" label="Username" />
                </div>
                <div className="admin-puser-confirm-con">
                  <div>
                    <Field name="agree" as={Checkbox} color="primary" />
                  </div>
                  <div>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      style={{ color: "coral" }}
                    >
                      I agree that this is dangerous & cannot be reverse
                    </Typography>
                  </div>
                </div>
                <div className="admin-puser-submitbtn-con">
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        size={15}
                        className="admin-assign-btn-progress"
                      />
                    ) : null}
                    {isSubmitting ? "Deleting..." : "Delete"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Spacer height={10} />
    </div>
  );
};

export default PermentUserRemoval;
