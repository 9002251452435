import React, { useEffect, useState } from "react";
import { RefreshRounded } from "@material-ui/icons";
import {
  Slide,
  Typography,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import "./UserActivity.scss";
import ActivityLogs from "../ActivityLogs";
import Spacer from "../../components/Spacer";
import authToken from "../../services/authToken";
import CopyRight from "../../components/Copyright";
import fetchActivityLogs from "../../services/graphql/userActivity";

export interface UserActivityProps {}

const UserActivity: React.SFC<UserActivityProps> = () => {
  const [fetching, setFetching] = useState(true);
  useEffect(() => {
    (async () => {
      if (authToken.hasToken) await fetchActivityLogs(false);
      setFetching(false);
    })();
  }, []);

  async function refreshLogs() {
    setFetching(true);
    await fetchActivityLogs(true);
    setFetching(false);
  }

  return (
    <>
      <div className="useractivity-main-wrapper">
        <div className="useractivity-inner-wrapper">
          <Slide in={true} direction="right" mountOnEnter unmountOnExit>
            <div>
              <Spacer height={25} />
              <div className="useractivity-title-progress-con">
                <div>
                  <Typography variant="h6" component="h5">
                    User activities
                  </Typography>
                </div>
                <div>
                  {fetching ? (
                    <CircularProgress
                      size={15}
                      color="primary"
                      className="useractivity-progress"
                    />
                  ) : (
                    <Tooltip title="Refresh logs">
                      <IconButton size="small" onClick={refreshLogs}>
                        <RefreshRounded color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
              <Spacer height={10} />
              <ActivityLogs heightOffset={250} />
            </div>
          </Slide>
        </div>
      </div>
      <CopyRight />
    </>
  );
};

export default UserActivity;
