import React from "react";
import { connect } from "react-redux";
import { Paper, Typography } from "@material-ui/core";
import { RecentActorsRounded } from "@material-ui/icons";

import "./Container.scss";
import LogItem from "../components/ActivityLogListItem";
import { ActivityLogsPayload } from "../interfaces/global-interfaces";

export interface ActivityLogsProps {
  show?: number;
  heightOffset: number;
  logs?: ActivityLogsPayload[];
}

const ActivityLogs: React.SFC<ActivityLogsProps> = ({
  logs,
  show,
  heightOffset,
}) => {
  return (
    <>
      <Paper elevation={3} className="activitylogs-main-paper">
        <div
          style={{
            overflow: "auto",
            minHeight: `calc(100vh - ${heightOffset}px)`,
            maxHeight: `calc(100vh - ${heightOffset}px)`,
          }}
        >
          {logs && logs.length > 0 ? (
            logs
              ?.splice(0, show ? show : logs.length)
              .map((eachLog) => (
                <LogItem
                  level={eachLog.level}
                  action={eachLog.action}
                  timestamp={eachLog.timestamp}
                  description={eachLog.description}
                  key={eachLog.timestamp + Math.random()}
                />
              ))
          ) : (
            <div className="activitylog-not-found-container">
              <div style={{ textAlign: "center" }}>
                <RecentActorsRounded
                  fontSize="small"
                  style={{ opacity: 0.5 }}
                />
                <Typography variant="subtitle2" component="p">
                  No users activity
                </Typography>
              </div>
            </div>
          )}
        </div>
      </Paper>
    </>
  );
};

function mapSateToProps(state: any, _: any) {
  const logs = state.activityLogs as ActivityLogsPayload[];
  return {
    logs,
  };
}

export default connect(mapSateToProps, null)(ActivityLogs);
