import gql from 'graphql-tag';
import { OperationContext } from 'urql/core';

import gqlClient from './gqlClient';
import authToken from '../authToken';
import store from '../../store/store';
import getProducts from './getProducts';
import alertService from '../alertService';
import productsAction from '../../actions/productActions';
import { ProductPayload } from '../../interfaces/global-interfaces';


export default async function addProduct(name: string, productId: string): Promise<boolean> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: "network-only"
    }

    const query = gql`
        mutation($name: String!, $productId: String!) {
            addProduct(
                name: $name,
                productId: $productId
            ){
                name
            }
        }
    `

    const response = await client.query(query, { name, productId }, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure', 'error');
            return false;
        }
        alertService(response.error.graphQLErrors[0].message, 'error');
        return false;
    }

    const productAdded = response.data.addProduct;
    await getProducts(true);
    alertService(`"${productAdded.name}" is added in products `, 'success')
    return true;
}



export async function removeProduct(productId: string): Promise<boolean> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: "network-only"
    }

    const query = gql`
        mutation($productId: String!) {
            deleteProduct(
                productId: $productId,
            )
        }
    `

    const response = await client.query(query, { productId }, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure', 'error');
            return false;
        }
        alertService(response.error.graphQLErrors[0].message, 'error');
        return false;
    }

    const oldProducts: ProductPayload[] = (store.getState() as any).products;
    const newProducts = oldProducts.filter(eachProduct => {
        if (eachProduct.productId !== productId) return eachProduct;
        return false;
    }) as ProductPayload[];

    store.dispatch(productsAction(newProducts));
    alertService(`"${productId}" is removed permently`, 'success')

    return true;
}