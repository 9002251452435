import React from "react";
import { connect } from "react-redux";
import { Field, Form, Formik, FormikHelpers } from "formik";
import {
  Typography,
  Button,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";

import "./Admin.scss";
import Spacer from "../../components/Spacer";
import { licenseAssignSchema } from "./schema";
import SmallTextField from "../../components/Custom/SmallTextField";
import { ProductPayload } from "../../interfaces/global-interfaces";
import assignLicensesHandle from "../../services/graphql/assignLicenses";

export interface AssignLicensesProps {
  products: ProductPayload[];
}

interface SubmitValues {
  amount: number;
  duration: number;
  productId: string;
  moderatorUsername: string;
}

const AssignLicenses: React.SFC<AssignLicensesProps> = ({ products }) => {
  const initVaues = {
    amount: 1,
    duration: 1,
    productId: "",
    moderatorUsername: "",
  };

  async function handleSubmit(
    values: SubmitValues,
    helper: FormikHelpers<SubmitValues>
  ) {
    const { amount, duration, productId, moderatorUsername } = values;
    helper.setSubmitting(true);
    const done = await assignLicensesHandle(
      amount,
      duration,
      productId,
      moderatorUsername
    );
    helper.setSubmitting(false);
    if (done) helper.resetForm();
  }

  return (
    <div>
      <div className="admin-assign-license-title-container">
        <Typography variant="subtitle1" component="p">
          Assign Licenses To Reseller
        </Typography>
      </div>
      <Spacer height={10} />
      <div className="admin-asign-inner-wrapper">
        <Formik
          validateOnMount
          onSubmit={handleSubmit}
          initialValues={initVaues}
          validationSchema={licenseAssignSchema}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <div className="admin-assign-inner-form">
                <div className="admin-assign-filed-wrapper-1">
                  <Field
                    as={SmallTextField}
                    name="moderatorUsername"
                    label="Reseller username"
                  />
                </div>
                <div className="admin-assign-filed-wrapper-2">
                  <Field
                    select
                    label="Product"
                    name="productId"
                    as={SmallTextField}
                  >
                    {products?.map((eachProduct) => (
                      <MenuItem
                        value={eachProduct.productId}
                        key={eachProduct.id}
                      >
                        {eachProduct.name}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
                <div className="admin-assign-filed-wrapper-3">
                  <Field
                    type="number"
                    name="duration"
                    as={SmallTextField}
                    label="Duration Days"
                  />
                </div>
                <div className="admin-assign-filed-wrapper-4">
                  <Field
                    name="amount"
                    type="number"
                    label="Amount"
                    as={SmallTextField}
                  />
                </div>
                <div className="admin-assign-filed-wrapper-2">
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        size={15}
                        className="admin-assign-btn-progress"
                      />
                    ) : null}
                    {isSubmitting ? "Assigning..." : "Assign"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Spacer height={10} />
    </div>
  );
};

function mapStateToProps(state: any, props: any) {
  const products: ProductPayload[] = state.products;
  return {
    products,
  };
}

export default connect(mapStateToProps, null)(AssignLicenses);
