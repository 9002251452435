import React from 'react';


export interface SpacerProps {
    height: number;
    width?: number;
}

const Spacer: React.SFC<SpacerProps> = ({ height, width }) => {
    return (
        <div style={{ height: `${height}px`, width: `${width + 'px' || 100 + '%'}` }} />
    );
}

export default Spacer;