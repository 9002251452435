import { History } from "history";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { RefreshRounded } from "@material-ui/icons";
import { withRouter, useParams } from "react-router-dom";
import {
  Paper,
  Button,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import "./Products.scss";
import authToken from "../../services/authToken";
import fetchProducts from "../../services/graphql/getProducts";
import { ProductPayload } from "../../interfaces/global-interfaces";

export interface ProductBoardProps {
  products?: ProductPayload[];
  history: History;
}

const ProductBoard: React.SFC<ProductBoardProps> = ({ products, history }) => {
  const { productId } = useParams();
  const [fetching, setFetching] = useState(true);
  const [productIds, setProductIds] = useState("");

  useEffect(() => {
    setProductIds(productId);
    (async function init() {
      if (authToken.hasToken) await handleRefresh();
    })();
  }, [productId]);

  async function handleRefresh() {
    setFetching(true);
    await fetchProducts();
    setFetching(false);
  }

  function navigateProduct(path: string) {
    history.push(path);
  }

  return (
    <>
      <div className="product-board-wrapper">
        <div>
          <Typography variant="h6" component="h5">
            Products
          </Typography>
        </div>
        <div>
          {fetching ? (
            <CircularProgress className="loading-progress" size={15} />
          ) : (
            <Tooltip title="Refresh products">
              <IconButton size="small" onClick={handleRefresh}>
                <RefreshRounded color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <Paper elevation={3}>
        <div className="products-inner-wrapper">
          {products
            ?.sort((a, b) => a.name.length - b.name.length)
            .map((eachProduct) => (
              <div key={eachProduct.id}>
                <Button
                  fullWidth
                  size="small"
                  variant="text"
                  key={eachProduct.id}
                  className="product-list-btn"
                  color={
                    eachProduct.productId === productIds ? "primary" : "default"
                  }
                  onClick={() =>
                    navigateProduct(`/products/${eachProduct.productId}`)
                  }
                >
                  {eachProduct.name}
                </Button>
              </div>
            ))}
        </div>
      </Paper>
    </>
  );
};

function mapStateToProps(state: any, _: any) {
  const products = state.products as ProductPayload[];
  return {
    products: [...products],
  };
}

export default connect(mapStateToProps, null)(withRouter(ProductBoard));
