import React, { useEffect, useState } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Paper as MainBody, CssBaseline } from "@material-ui/core";
import { MuiThemeProvider as ThemeProvider } from "@material-ui/core/styles";

import history from "./history";
import Spacer from "./components/Spacer";
import Notify from "./containers/Notify";
import NavBar from "./components/NavMenu";
import darkTheme from "./theme/dark.theme";
import Admin from "./containers/admin/Admin";
import TopAppBar from "./components/TopAppBar";
import Reset from "./containers/auth/Reset/Reset";
import GetLicenes from "./components/GetLicenses";
import Signup from "./containers/auth/Signup/Signup";
import Signin from "./containers/auth/Signin/Signin";
import Settings from "./containers/settings/Settings";
import Products from "./containers/products/Products";
import Dashboard from "./containers/dashboard/Dashboard";
import UserActivity from "./containers/userActivity/UserActivity";

export interface RoutesProps {}

const Routes: React.SFC<RoutesProps> = () => {
  const [showNav, setShowNav] = useState(true);

  useEffect(() => {
    history.listen(() => {
      const location = history.location.pathname;
      if (location === "/login" || location === "/signup" || location === '/reset') {
        setShowNav(false);
      } else {
        setShowNav(true);
      }
    });
  }, []);

  return (
    <CssBaseline>
      <ThemeProvider theme={darkTheme.theme}>
        <MainBody
          elevation={0}
          className="main-app-body"
          style={{ backgroundColor: darkTheme.background, height: "100vh" }}
          square
        >
          <Router history={history}>
            {showNav ? (
              <>
                <TopAppBar />
                <Spacer height={10} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ minWidth: "850px", maxWidth: "850px" }}>
                    <NavBar />
                  </div>
                </div>
              </>
            ) : null}
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/admin" component={Admin} />
              <Route exact path="/reset" component={Reset} />
              <Route exact path="/login" component={Signin} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/settings" component={Settings} />
              <Route path="/products/:productId?" component={Products} />
              <Route exact path="/get-licenses" component={GetLicenes} />
              <Route exact path="/user-activity" component={UserActivity} />
              <Redirect from="*" to="/" />
            </Switch>
          </Router>
        </MainBody>
        <Notify />
      </ThemeProvider>
    </CssBaseline>
  );
};

// function mapStateToProps(state: any, props: any) {
//     const theme = state.appTheme.appTheme;
//     return {
//         appTheme: theme
//     }
// }

export default Routes;
