import gql from "graphql-tag";
import { OperationContext } from "urql/core";

import gqlClient from "./gqlClient";
import authToken from "../authToken";
import alertService from "../alertService";
import {
  GetUserInfo,
  UpdateInfoValues,
} from "../../interfaces/global-interfaces";

export async function getUserInfo(): Promise<GetUserInfo | null> {
  const client = gqlClient.getClient();

  const options: Partial<OperationContext> = {
    fetchOptions: () => {
      const token = authToken.getToken();
      return {
        headers: { "access-token": token },
      };
    },
    requestPolicy: "network-only",
  };

  const query = gql`
    {
      getUserInfo {
        dob
        email
        phone
        country
        lastName
        firstName
        countryState
      }
    }
  `;

  const response = await client.query(query, {}, options).toPromise();

  if (response.error) {
    if (response.error.networkError) {
      alertService("Network failure.", "error");
      return null;
    }

    alertService(response.error.graphQLErrors[0].message, "error");
    return null;
  }

  return response.data.getUserInfo as GetUserInfo;
}

export async function updateUserInfo(
  updateInfo: UpdateInfoValues
): Promise<void> {
  const client = gqlClient.getClient();

  const options: Partial<OperationContext> = {
    fetchOptions: () => {
      const token = authToken.getToken();
      return {
        headers: { "access-token": token },
      };
    },
    requestPolicy: "network-only",
  };

  const query = gql`
    mutation(
      $dob: String
      $phone: String
      $country: String
      $lastName: String
      $firstName: String
      $countryState: String
    ) {
      updateUserInfo(
        dob: $dob
        phone: $phone
        country: $country
        lastName: $lastName
        firstName: $firstName
        countryState: $countryState
      )
    }
  `;

  const response = await client
    .mutation(query, updateInfo, options)
    .toPromise();

  if (response.error) {
    if (response.error.networkError) {
      alertService("Network failure.", "error");
      return;
    }

    alertService(response.error.graphQLErrors[0].message, "error");
    return;
  }

  const result = response.data.updateUserInfo as boolean;
  if (result) {
    alertService("Updated Sucessfully", "success");
  } else {
    alertService("Something went wrong", "error");
  }
}
