import gql from "graphql-tag";
import { OperationContext } from "urql/core";

import gqlClient from "./gqlClient";
import authToken from "../authToken";
import store from "../../store/store";
import alertService from "../alertService";
import productAction from "../../actions/productActions";
// import { ProductPayload } from "../../interfaces/global-interfaces";

export default async function getAllProducts(noCache?: boolean): Promise<void> {
  const client = gqlClient.getClient();

  const options: Partial<OperationContext> = {
    fetchOptions: () => {
      const token = authToken.getToken();
      return {
        headers: { "access-token": token },
      };
    },
    requestPolicy: noCache ? "network-only" : "cache-first",
  };

  const query = gql`
    {
      getAllProducts {
        id
        name
        productId
      }
    }
  `;

  const response = await client.query(query, {}, options).toPromise();

  if (response.error) {
    if (response.error.networkError) {
      alertService("Network failure", "error");
      return;
    }
    alertService(response.error.graphQLErrors[0].message, "error");
    return;
  }

  store.dispatch(productAction(response.data.getAllProducts));
}
