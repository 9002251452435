import React from "react";
import { History } from "history";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { Typography, Button, CircularProgress } from "@material-ui/core";

import "./Settings.scss";
import schema from "./schema";
import Spacer from "../../components/Spacer";
import PasswordField from "../../components/Custom/PasswordField";
import changePassword from "../../services/graphql/updatePassword";

export interface ResetPasswordProps {
  history: History;
}

interface ResetPassFormValues {
  password: string;
  retypePassword: string;
}

const ResetPassword: React.SFC<ResetPasswordProps> = ({ history }) => {
  const intiValues = { password: "", retypePassword: "" };

  async function handleSubmit(
    values: ResetPassFormValues,
    helper: FormikHelpers<ResetPassFormValues>
  ) {
    helper.setSubmitting(true);
    const { password } = values;
    await changePassword(password, history);
    helper.setSubmitting(false);
  }

  return (
    <div>
      <div className="reset-password-title-container">
        <Typography variant="subtitle1" component="p">
          Update Password
        </Typography>
      </div>
      <Spacer height={10} />
      <div className="reset-password-form-wrapper">
        <Formik
          validateOnMount
          onSubmit={handleSubmit}
          validationSchema={schema}
          initialValues={intiValues}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <div className="reset-password-inner-wrapper">
                <div className="reset-password-field-wrapepr">
                  <Field
                    name="password"
                    as={PasswordField}
                    label="New Password"
                  />
                </div>
                <div className="reset-password-field-wrapepr">
                  <Field
                    as={PasswordField}
                    name="retypePassword"
                    label="Retype Password"
                  />
                </div>
                <div className="reset-password-submit-wrapper">
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        size={15}
                        style={{ marginRight: "10px" }}
                      />
                    ) : null}
                    {isSubmitting ? "Updating ..." : "Update Password"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Spacer height={10} />
    </div>
  );
};

export default withRouter(ResetPassword);
