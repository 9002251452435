import gql from 'graphql-tag';
import { OperationResult, OperationContext } from 'urql/core';

import authToken from '../authToken';
import alertService from '../alertService';
import gqlClient from '../graphql/gqlClient';
import { TokenType, UserReport } from '../../interfaces/global-interfaces';


/** 
 * @description create a new user
 * Role: any
*/
export async function signupUser(
    dob: string,
    email: string,
    phone: string,
    password: string,
    firstName: string,
    lastName: string,
    username: string,
    country: string,
    countryState: string
): Promise<OperationResult> {
    const client = gqlClient.getClient();

    const variables = {
        dob,
        email,
        phone,
        password,
        firstName,
        lastName,
        username,
        country: (country === '' ? undefined : country) as any,
        countryState
    }

    const query = gql`
                mutation(
                    $dob: String
                    $email: String!
                    $phone: String
                    $password: String!
                    $firstName: String
                    $lastName: String
                    $username: String!
                    $country: String
                    $countryState: String
                ) {
                        signUp(
                        dob:$dob,
                        email:$email,
                        phone:$phone,
                        password:$password,
                        firstName:$firstName,
                        lastName:$lastName,
                        username:$username,
                        country:$country,
                        countryState:$countryState
                    )
                }
            
    `

    try {
        const result = await client.mutation(query, variables).toPromise();
        return result;
    } catch (err) {
        return null as any;
    }
}

/**
 * @description Signup user and set access_token & refresh_token to localStorage 
 * Role: any
 */
export async function signInUser(
    username: string,
    password: string,
    machineId: string
): Promise<OperationResult> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        requestPolicy: "network-only"
    }

    const variables = {
        username,
        password,
        machineId
    }

    const query = gql`
    query($username: String!, $password: String!, $machineId: String!){
            signIn(
                username: $username,
                password: $password,
                machineId: $machineId
            ){
                access_token
                refresh_token
            }
        }`

    try {
        const result = await client.query(query, variables, options).toPromise()
        if (result.data) {
            // localStorage.setItem(TokenType.AccessToken, result.data.signIn.access_token);
            authToken.setToken(result.data.signIn.access_token);
            localStorage.setItem(TokenType.RefreshToken, result.data.signIn.refresh_token);
            gqlClient.resetClient();
        }
        return result;
    } catch (err) {
        return null as any
    }

}

/**
 * @description Block a user ( required Role: admin)
 */
export async function blockUser(username: string, action: 'Block' | 'Unblock' = 'Block'): Promise<OperationResult> {
    const client = gqlClient.getClient();

    const token = authToken.getToken()

    const headers = new Headers({
        "access-token": token
    })
    const options: Partial<OperationContext> = {
        fetchOptions: {
            headers
        }
    }

    const variables = {
        username
    }

    const blockQuery = gql`
    mutation {
        blockUser($username: String!){
            id
            blocked
        }     
    }`

    const unBlockQuery = gql`
    mutation {
        blockUser($username: String!){
            id
            blocked
        }     
    }`

    try {
        const result = await client.mutation(action === 'Block' ? blockQuery : unBlockQuery, variables, options).toPromise();
        return result;
    } catch (err) {
        return null as any;
    }
}


export async function permentUserRemoval(username: string): Promise<boolean> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken()
            return {
                headers: { "access-token": token }
            }
        }
    }

    const query = gql`
        mutation($username: String!){
            permenentUserRemoval(username: $username)
        }
    `

    const response = await client.mutation(query, { username }, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure.', 'error');
            return false;
        }

        alertService(response.error.graphQLErrors[0].message, 'error');
        return false;
    }
    if (response.data.permenentUserRemoval) {
        alertService(`User ${username} permently removed`, 'success');
        return true;
    }

    alertService(`Fail to remove User ${username}`, 'error');
    return false
}


export async function getUserReport(username: string): Promise<UserReport | null> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken()
            return {
                headers: { "access-token": token }
            }
        }
    }

    const query = gql`
        mutation($username: String!){
            getUserReport(username: $username){
                sold
                total
                active
                blocked
                username
            }
        }
    `

    const response = await client.mutation(query, { username }, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure.', 'error');
            return null;
        }

        alertService(response.error.graphQLErrors[0].message, 'error');
    }
    if (response.data.permenentUserRemoval) {
        alertService(`User ${username} permently removed`, 'success');
        return null;
    }

    return { ...response.data.getUserReport, open: true } as UserReport;
}