import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import { Snackbar, Slide } from "@material-ui/core";

import { ALERT_TIMEOUT } from "../../constants.json";
import notificationAtion from "../actions/notificationAction";
import {
  Alert as AlertAction,
  AlertActionType,
} from "../interfaces/global-interfaces";

export interface NotifyProps {
  open: boolean;
  message: string;
  close: () => any;
  severity: "success" | "warning" | "error" | "info";
}

const Notify: React.SFC<NotifyProps> = ({ message, severity, open, close }) => {
  const handleClose = () => {
    close();
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      TransitionComponent={Slide}
      autoHideDuration={ALERT_TIMEOUT}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      ClickAwayListenerProps={{
        onClickAway: () => false,
      }}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={severity || "success"}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

const mapPropsToState = (state: any) => {
  const { open, message, severity } = state.notification;
  return {
    open,
    message,
    severity,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AlertActionType>) => {
  return {
    close: () => dispatch(notificationAtion(AlertAction.Close)),
  };
};

export default connect(mapPropsToState, mapDispatchToProps)(Notify);
