import gql from 'graphql-tag';
import { OperationContext } from 'urql/core';

import gqlClient from './gqlClient';
import authToken from '../authToken';
import alertService from '../alertService';


export default async function getLicenses(
    id: string
): Promise<boolean> {
    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: "network-only"
    }

    const query = gql`
        mutation($id: ID!){
            toggleBlock(id:$id){
                blocked
            }
        }
    `

    const response = await client.query(query, { id }, options).toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure', 'error');
            return false;
        }
        alertService(response.error.graphQLErrors[0].message, 'error');
        return false;
    }
    const blockStatus = response.data.toggleBlock.blocked as boolean;
    alertService(blockStatus ? 'License blocked' : 'License Unblocked', blockStatus ? 'warning' : 'success')
    return true;
}