import gql from 'graphql-tag';
import { OperationContext } from 'urql/core';

import gqlClient from './gqlClient';
import authToken from '../authToken';
import store from '../../store/store';
import alertService from '../alertService';
import licenseAction from '../../actions/getLicenseAction';
import { OrderLicenseBy } from '../../interfaces/global-interfaces';


export default async function getLicenses(
    take: number,
    page: number,
    productId: string,
    orderBy: OrderLicenseBy,
) {

    const client = gqlClient.getClient();

    const options: Partial<OperationContext> = {
        fetchOptions: () => {
            const token = authToken.getToken();
            return {
                headers: { "access-token": token }
            }
        },
        requestPolicy: "network-only"
    }

    const query = gql`
        query($take: Int!, $page: Int!, $productId: String!, $orderBy: String!){
            getLicenses(take: $take, page: $page, productId: $productId, orderBy: $orderBy){
                licenses{
                    id
                    key
                    sold
                    blocked
                    username
                    expireDays
                    activationDate
                }
                total                
            }
        }
    `

    const response = await client.query(query, { take, page, productId, orderBy }, options)
        .toPromise();

    if (response.error) {
        if (response.error.networkError) {
            alertService('Network failure', 'error');
            return;
        }
        alertService(response.error.graphQLErrors[0].message, 'error');
        return;
    }
    store.dispatch(licenseAction(response.data.getLicenses));
}