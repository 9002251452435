import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { useField, useFormikContext } from "formik";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export interface DatePickerProps {
  name: string;
  value: string;
  label: string;
  onChange: (val: any) => any;
}

const DatePicker: React.SFC<DatePickerProps> = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setFieldValue(field.name, date.toISOString());
    } else {
      setFieldValue(field.name, "");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...field}
        {...props}
        fullWidth
        size="small"
        margin="none"
        error={false}
        helperText=""
        format="dd/MM/yyyy"
        inputVariant="outlined"
        onChange={handleDateChange}
        InputProps={{ disabled: true }}
        InputLabelProps={{ shrink: true }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
