import * as yup from 'yup';

export const licenseAssignSchema = yup.object({
    moderatorUsername: yup.string().required("Reseller username is required"),
    productId: yup.string().required('Product is required'),
    duration: yup.number().required('required').min(1, 'min 1 day'),
    amount: yup.number().required('required').min(1, 'min 1')
})

export const blockUnblockuserSchema = yup.object({
    username: yup.string().required('Username is required'),
    block: yup.number().required('Action is required')
})

export const addProductSchema = yup.object({
    name: yup.string().required('Product name is required').min(2).max(100),
    productId: yup.string().required('Product ID is required').min(5).max(20)
})

export const addFeedSchema = yup.object({
    title: yup.string().required('Title is required').min(5).max(50),
    description: yup.string().required('Description is required').min(5)
})

export const permentUserDelSchema = yup.object({
    agree: yup.bool().oneOf([true]).required('Agreement should be checked'),
    username: yup.string().required('Username is required')
})

export const userReportSchema = yup.object({
    username: yup.string().required('Username is required')
})