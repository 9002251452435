import React, { useState } from "react";

import RequestOtp from "./RequestOtp";
import ResetPasswordCom from "./ResetPassword";

export interface ResetPasswordProps {}

const ResetPassword: React.SFC<ResetPasswordProps> = () => {
  const [otpSended, setOtpSended] = useState(false);
  return (
    <>
      {otpSended ? (
        <ResetPasswordCom />
      ) : (
        <RequestOtp setOtpSended={setOtpSended} />
      )}
    </>
  );
};

export default ResetPassword;
