import { History } from "history";
import { FormikHelpers } from "formik";

import { FormSubmitValues } from "./types/types";
import alertService from "../../../services/alertService";
import { signupUser } from "../../../services/graphql/user";

interface SignUpHandlerResponse {
  success: boolean;
  error: string;
}

export async function signUpHandler(
  singnUpDetails: FormSubmitValues,
  helper: FormikHelpers<FormSubmitValues>,
  history: History
): Promise<void> {
  helper.setSubmitting(true);
  const {
    dob,
    email,
    state,
    phone,
    country,
    lastName,
    password,
    username,
    firstName,
    repassword,
  } = singnUpDetails;
  if (password !== repassword) {
    helper.setErrors({ repassword: "Password not match." });
    return;
  }
  const response = await signupUser(
    dob,
    email,
    phone,
    password,
    firstName,
    lastName,
    username,
    country,
    state
  );

  // Server failure
  if (!response) {
    helper.setSubmitting(false);
    alertService("Something went wrong", "error");
    return;
  }

  // Request error or validation error
  if (response.error) {
    helper.setSubmitting(false);
    if (response.error.networkError) {
      alertService("Network failure", "error");
      return;
    }
    alertService(response.error.graphQLErrors[0].message, "error");
    return;
  }

  // Sucess
  helper.resetForm();
  history.push("/login");
  helper.setSubmitting(false);
}
