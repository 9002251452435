import React from "react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import {
  Typography,
  Button,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";

import "./Admin.scss";
import Spacer from "../../components/Spacer";
import { blockUnblockuserSchema } from "./schema";
import SmallTextField from "../../components/Custom/SmallTextField";
import blockUnBlockUser from "../../services/graphql/blockUnblockUser";

export interface AssignLicensesProps {}

interface SubmitValues {
  block: number;
  username: string;
}

const BlockAndUnblockUser: React.SFC<AssignLicensesProps> = () => {
  const initVaues = { username: "", block: 1 };

  async function handleSubmit(
    values: SubmitValues,
    helper: FormikHelpers<SubmitValues>
  ) {
    const { username, block } = values;
    helper.setSubmitting(true);

    const done = await blockUnBlockUser(username, block === 1 ? true : false);
    helper.setSubmitting(false);
    if (done) helper.resetForm();
  }

  return (
    <div>
      <div className="admin-user-title-wrapper">
        <Typography variant="subtitle1" component="p">
          Block / UnBlock User
        </Typography>
      </div>
      <Spacer height={10} />
      <div className="admin-user-form-wrapper">
        <Formik
          validateOnMount
          onSubmit={handleSubmit}
          initialValues={initVaues}
          validationSchema={blockUnblockuserSchema}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <div className="admin-user-inner-form-con">
                <div className="admin-user-field-wrapper-1">
                  <Field as={SmallTextField} name="username" label="Username" />
                </div>
                <div className="admin-user-field-wrapper-2">
                  <Field select name="block" label="Action" as={SmallTextField}>
                    <MenuItem value={1} key="blockuser">
                      Block
                    </MenuItem>
                    <MenuItem value={0} key="unblockuser">
                      UnBlock
                    </MenuItem>
                  </Field>
                </div>
                <div className="admin-user-field-wrapper-3">
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        size={15}
                        className="admin-assign-btn-progress"
                      />
                    ) : null}
                    Block\UnBlock
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Spacer height={10} />
    </div>
  );
};

export default BlockAndUnblockUser;
