import * as yup from "yup";

export const schema1 = yup.object({
  username: yup
  .string()
  .required()
  .max(50, 'Max 50 characters')
});


export const schema2 = yup.object({
    otp: yup
    .string()
    .required()
    .min(6, 'Invalid OTP')
    .max(6, 'Invalid OTP'),
    password: yup
    .string()
    .required()
    .min(10, 'Minimum 10 characters')
    .max(200, 'Maximum 200 characters'),
    rePassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Password not matched')
})