import { QuickLook, QuickLookAction, QuickLookPayload } from "../interfaces/global-interfaces";


const intiSate: QuickLookPayload = {
    total: 0,
    sold: 0,
    active: 0,
    blocked: 0
}

export default function quickLookReducer(state: QuickLookPayload = intiSate, action: QuickLook) {
    switch (action.type) {
        case QuickLookAction.Update:
            state = {
                ...state,
                ...action.payload
            }
            return state; 
        default:
            return state;
    }
}