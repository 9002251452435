import React from "react";
import { Form, Formik, Field } from "formik";
import { Link } from "react-router-dom";
import {
  Slide,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";

import { reqReset } from "./resetHandler";
import { schema1 } from "./immutable/schema";
import Spacer from "../../../components/Spacer";
import AppLogo from "../../../../assets/logo.png";
import { step1 } from "./immutable/intialValues";
import Copyright from "../../../components/Copyright";
import SmallTextField from "../../../components/Custom/SmallTextField";

export interface RequestOtpProps {
  setOtpSended: (state: boolean) => void;
}

const RequestOtp: React.SFC<RequestOtpProps> = ({ setOtpSended }) => {
  return (
    <>
      <div className="main-wrapper">
        <Spacer height={10} />
        <div>
          <img alt="logo" src={AppLogo} className="auth-signin-logo-img" />
        </div>
        <Spacer height={20} />

        <Formik
          validateOnMount
          validationSchema={schema1}
          initialValues={step1}
          onSubmit={async (values, helper) =>
            await reqReset(values, helper, setOtpSended)
          }
        >
          {({ isValid, isSubmitting }) => (
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
              <Paper elevation={3} className="auth-signin-paper">
                <Form>
                  <div>
                    <Typography variant="h5" component="h5">
                      Reset
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle2" component="h6">
                      Reset your password
                    </Typography>
                  </div>

                  <Spacer height={25} />

                  <div>
                    <div>
                      <Field
                        name="username"
                        label="Username or Email"
                        placeholder="alicewhiteman99"
                        as={SmallTextField}
                      />
                    </div>

                    <Spacer height={15} />
                  </div>

                  <Spacer height={25} />

                  <div className="input-wrapper">
                    <div className="input_fields_div">
                      <Link to="/login" className="link">
                        <Typography variant="subtitle1" component="p">
                          Back to Login
                        </Typography>
                      </Link>
                    </div>

                    <div className="input_fields_div right">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting || !isValid}
                        variant="contained"
                      >
                        {isSubmitting ? (
                          <CircularProgress
                            className="submit-progress"
                            size={20}
                          />
                        ) : (
                          ""
                        )}{" "}
                        {isSubmitting ? "Verifying" : "Next"}
                      </Button>
                    </div>
                  </div>
                </Form>
              </Paper>
            </Slide>
          )}
        </Formik>
        <Copyright />
      </div>
    </>
  );
};

export default RequestOtp;
