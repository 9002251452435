import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#8a85ff'
        },
        secondary: {
            main: '#282c34'
        },
        background: {
            paper: '#282c34'
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        subtitle2: {
            opacity: 0.4,
            fontWeight: 400,
            fontSize: '15px'
        },
        h6: {
            opacity: 0.4
        }
    },
    overrides: {
        MuiAppBar: {
            root: {
                backgroundColor: '#282c34 !important'
            }
        },
        MuiDivider: {
            root: {
                height: '20px !important',
                backgroundColor: undefined,
                borderLeft: '2px solid #42464d',
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '15px',
                backgroundColor: '#1e1e1e'
            }
        }
    }
})

export default { theme, background: '#1c2025' }