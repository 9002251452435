import { History } from 'history';
import { FormikHelpers } from 'formik';



import { SigninData } from './types/signinTypes';
import alertService from '../../../services/alertService';
import getFingerPrint from '../../../services/fingerPrint';
import { signInUser } from '../../../services/graphql/user';



export default async function signHandler(
    values: SigninData,
    helper: FormikHelpers<SigninData>,
    history: History
): Promise<void> {
    helper.setSubmitting(true);
    const { username, password } = values;
    const fingerPrint = await getFingerPrint();
    const response = await signInUser(username, password, fingerPrint);

    if (!response) {
        helper.setSubmitting(false);
        alertService('Internal server error', 'error')
        return;
    }

    if (response.error) {
        helper.setSubmitting(false);
        if (response.error.networkError) {
            alertService('Network failure', 'error')
            return;
        }
        helper.setErrors({ username: '', password: 'Invalid username or password' })
        alertService(response.error.graphQLErrors[0].message, 'error')
        return;
    }

    helper.resetForm();
    history.push('/');
}