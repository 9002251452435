import { Action } from "redux";

export enum TokenType {
  AccessToken = "access-token",
  RefreshToken = "refresh_token",
}

export enum OrderLicenseBy {
  Active = "Active",
  Blocked = "Blocked",
  Sold = "Sold",
  None = "None",
}

export interface LicensePayload {
  id: string;
  key: string;
  sold: boolean;
  country: string;
  blocked: boolean;
  username: string;
  expireDays: number;
  userBlocked: boolean;
  userVerified: boolean;
  activationDate: string;
}

export interface LicensesWithTotal {
  licenses: LicensePayload[];
  total: number;
}

export enum LicenseAction {
  Update = "UPDATE_LICENES",
}

export interface License extends Action {
  payload: LicensesWithTotal;
}

export enum Theme {
  Dark = "dark",
  Light = "light",
}

export type AlertSeverity = "success" | "warning" | "error" | "info";

export interface AlertPayload {
  message?: string;
  severity?: AlertSeverity;
  open?: boolean;
}

export interface AlertActionType extends Action {
  payload: AlertPayload;
}

export enum Alert {
  Open = "OPEN_NOTIFICATION",
  Close = "CLOSE_NOTIFICATION",
}

export interface QuickLookPayload {
  total: number;
  sold: number;
  active: number;
  blocked: number;
}

export interface QuickLook extends Action {
  payload: QuickLookPayload;
}

export enum QuickLookAction {
  Update = "UPDATE_QUICKLOOK",
}

export interface ActivityLogsPayload {
  id: string;
  timestamp: string;
  description: string;
  action: "ACTIVATION" | "VERIFICATION";
  level: "ERROR" | "WARNNING" | "INFO";
}

export interface ActivityLogs extends Action {
  payload: ActivityLogsPayload[];
}

export enum ActivityLogsAction {
  Update = "UPDATE_ACTIVITY_LOGS",
}

export interface FeedPayload {
  id: string;
  title: string;
  // excerpt: string;
  timestamp: string;
  description: string;
}

export enum FeedsAction {
  Update = "UPDATE_FEED",
}

export interface Feeds extends Action {
  payload: FeedPayload[];
}

export interface ProductPayload {
  id: string;
  name: string;
  productId: string;
}

export enum ProductsAction {
  Update = "UPDATE_PRODUCTS",
}

export interface Products extends Action {
  payload: ProductPayload[];
}

export interface UserReport {
  sold: string;
  total: string;
  active: string;
  open: boolean;
  blocked: string;
  username: string;
}

export interface GetUserInfo {
  firstName?: string;
  lastName?: string;
  email: string;
  phone?: string;
  dob?: string | null;
  country?: string;
  countryState?: string;
}
export interface UpdateInfoValues {
  dob?: string;
  phone?: string;
  country?: string;
  lastName?: string;
  firstName?: string;
  countryState?: string;
}