/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Paper, Slide, Typography } from "@material-ui/core";

import "./Component.scss";
import Spacer from "./Spacer";
import Copyright from "./Copyright";
import AppLogo from "../../assets/logo.png";
import GmailLogo from "../../assets/gmail.svg";
import SeoClerk from "../../assets/seoclerk.png";
import WhatsAppLogo from "../../assets/whatsapp.svg";

export interface GetLicensesProps {}

const GetLicenses: React.SFC<GetLicensesProps> = () => {
  return (
    <>
      <div className="get-license">
        <div className="getlicense-inner-section">
          <Spacer height={25} />

          <Slide direction="right" in={true} mountOnEnter unmountOnExit>
            <Paper style={{ padding: "2%" }}>
              <div className="getlicense-inner-wrapper">
                <div>
                  <Typography variant="h5" component="h5">
                    Visit shop to get license
                  </Typography>
                </div>

                <Spacer height={20} />

                <div className="site-container">
                  <div className="site-container-inner">
                    <div className="site-link-container">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://gravitonapps.com"
                      >
                        <img
                          src={AppLogo}
                          alt="Whatsapp logo"
                          className="site-logo-link"
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <Spacer height={50} />

                <div>
                  <Typography variant="h5" component="h5">
                    Or contact us to get licenses
                  </Typography>
                </div>

                <Spacer height={30} />

                <div className="social-network-container">
                  <div className="social-network-wrapper">
                    <div>
                      <img
                        src={WhatsAppLogo}
                        alt="Whatsapp logo"
                        className="social-network-img"
                      />
                    </div>
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="social-network-link"
                        href="https://api.whatsapp.com/send?phone=923368131774"
                      >
                        <Typography variant="subtitle1" component="p">
                          +923368131774
                        </Typography>
                      </a>
                    </div>
                  </div>
                  <div className="social-network-wrapper">
                    <div>
                      <img
                        src={SeoClerk}
                        alt="Whatsapp logo"
                        className="social-network-img"
                      />
                    </div>
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="social-network-link"
                        href="https://www.seoclerk.com/user/gravitonapps"
                      >
                        <Typography variant="subtitle1" component="p">
                          gravitonapps
                        </Typography>
                      </a>
                    </div>
                  </div>
                  <div className="social-network-wrapper">
                    <div>
                      <img
                        src={GmailLogo}
                        alt="Whatsapp logo"
                        className="social-network-img"
                      />
                    </div>
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="social-network-link"
                        href="mailto:gravitonapps99@gmail.com"
                      >
                        <Typography variant="subtitle1" component="p">
                          gravitonapps99@gmail.com
                        </Typography>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </Slide>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default GetLicenses;
