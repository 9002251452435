import React from "react";
import { Typography, CircularProgress } from "@material-ui/core";

import "./Dashboard.scss";
import Feeds from "../Feeds";
import ActivityLogs from "../ActivityLogs";
import Spacer from "../../components/Spacer";

export interface FeedAndActivityProps {
  fetching: boolean;
}

const FeedAndActivity: React.SFC<FeedAndActivityProps> = ({ fetching }) => {
  return (
    <div className="feedactivity-main-container">
      <div className="dashboard-feeds-wrapper">
        <div className="dashboard-feeds-inner-wrapper">
          <div>
            <Typography variant="h6" component="h5">
              Feeds
            </Typography>
          </div>
          <div>
            {fetching ? (
              <CircularProgress
                size={15}
                color="primary"
                className="dashboard-activity-progress"
              />
            ) : null}
          </div>
        </div>
        <Spacer height={10} />
        <Feeds heightOffset={420} />
      </div>

      <div className="dashboard-activity-main-contaiener">
        <div className="dashboard-activity-inner-wrapper">
          <div>
            <Typography variant="h6" component="h5">
              Recent users activity
            </Typography>
          </div>
          <div>
            {fetching ? (
              <CircularProgress
                size={15}
                color="primary"
                className="dashboard-activity-progress"
              />
            ) : null}
          </div>
        </div>
        <Spacer height={10} />
        <ActivityLogs heightOffset={420} show={30} />
      </div>
    </div>
  );
};

export default FeedAndActivity;
